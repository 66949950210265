import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { LoggerService } from '@lims-common-ux/lux';
import { AppStateService } from '../../app-state.service';
import { PREVENTS_SAVE_REQUEST } from '../../app.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { Test } from '../../shared/models/test.model';
import { OrderEntryService } from '../order-entry.service';

@Injectable({
  providedIn: 'root',
})
export class TestCodesService {
  private _unresolvedEorderTestCodes: BehaviorSubject<string[]> = new BehaviorSubject([]);
  unresolvedEorderTestCodes$: Observable<string[]> = this._unresolvedEorderTestCodes;

  private searchingForTests: BehaviorSubject<boolean> = new BehaviorSubject(false);
  searchingForTests$: Observable<boolean> = this.searchingForTests;

  private requestingTest: BehaviorSubject<boolean> = new BehaviorSubject(false);
  requestingTest$: Observable<boolean> = this.requestingTest;

  constructor(
    private appState: AppStateService,
    private http: HttpClient,
    private loggerService: LoggerService,
    private orderEntryService: OrderEntryService
  ) {}

  get searchUrl(): string {
    return this.appState.referenceData._links.testSearch.href;
  }

  get unresolvedEorderTestCodes() {
    return this._unresolvedEorderTestCodes.value;
  }

  set unresolvedEorderTestCodes(unresolved: string[]) {
    this._unresolvedEorderTestCodes.next(unresolved);
  }

  getTestCode(testCode): Observable<any> {
    this.loggerService.logAction('oelog-test-code-search', {
      testCode: testCode,
    });

    return this.http.get(testCode._links.self.href, {
      context: new HttpContext().set(PREVENTS_SAVE_REQUEST, true),
    });
  }

  isExistingAccession(): boolean {
    return !!this.appState.existingAccession;
  }

  isOrderedTest(testId: string): boolean {
    const found = this.appState.orderedTests?.filter((orderedTest) => orderedTest.testId === testId);
    return found?.length > 0;
  }

  isExistingAddOn(testId: string): boolean {
    const found = this.appState.existingAccession?.addOns?.filter((existingAddOn) => existingAddOn.testId === testId);
    return found?.length > 0;
  }

  prepareTestRequests(tests: Test[]): Observable<any>[] {
    this.searchingForTests.next(true);

    const testReqs: Observable<any>[] = [];

    tests.forEach((test) => {
      testReqs.push(this.getTestCode(test));
    });

    this.requestingTest.next(true);

    return testReqs;
  }

  resetTestSearch() {
    this.searchingForTests.next(false);
  }

  findElectronicOrderTests(electronicOrderTests: any[]) {
    const allTestsAreResolved = electronicOrderTests?.every((eoTest) => eoTest.resolved === true);

    if (!allTestsAreResolved) {
      this._unresolvedEorderTestCodes.next(electronicOrderTests?.map((eoTest) => eoTest.code));

      this.loggerService.logAction(
        'eorder-has-unresolved-tests',
        this.orderEntryService.getLogPayload('Electronic Order Had Unresolved Tests')
      );
    }

    if (electronicOrderTests?.length > 0 && allTestsAreResolved) {
      return this.prepareTestRequests(
        electronicOrderTests.map((eoTest) => {
          return {
            testCode: eoTest.code,
            _links: eoTest._links,
          } as Test;
        })
      );
    } else {
      return [];
    }
  }
}

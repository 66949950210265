import { map, Observable, tap } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { OrderEntryService } from '../order-entry/order-entry.service';
import { AssayWithParent, Test, TestType } from '../shared/models/test.model';
import { Sample } from '../shared/models/sample.model';
import {
  SampleTestAssociation,
  SampleTestAssociationError,
  SampleTestAssociationErrorCode,
  SampleTestAssociationRes,
} from './sample-test-association';
import { IndexResource } from '../interfaces/indexResource.interface';
import { INDEX_RESOURCE } from '../application-init.service';
import { AppStateService } from '../app-state.service';
import { PREVENTS_SAVE_REQUEST } from '../app.component';

@Injectable({
  providedIn: 'root',
})
export class SamplesAssociationsService {
  private get associationUrl() {
    // needs to be a getter as the index resource gets updated during init
    return this.indexResource._links.sampleTestAssociation.href;
  }

  hasFormErrors = false;
  hasPerformedAssociationsRequest = false;

  // To track user changes
  private updatedSampleTestAssociations = new Map<string, string>();

  // Received from server
  private expectedSampleTestAssociations = new Map<string, string>();

  // Errors received from server
  private sampleTestAssociationErrors = new Map<string, SampleTestAssociationErrorCode>();

  // the reset state we expect to return to if associations are not approved
  private resetSampleTestAssociations = new Map<string, string>();

  constructor(
    @Inject(INDEX_RESOURCE) private indexResource: IndexResource,
    private http: HttpClient,
    private orderEntryService: OrderEntryService,
    private appStateService: AppStateService
  ) {}

  associateSamples(
    samples: Sample[],
    testCodes: Test[],
    keepExistingAssociations = false
  ): Observable<SampleTestAssociationRes | ErrorEvent> {
    const headers: HttpHeaders = this.appStateService.addTraceHeader(new HttpHeaders());
    let params = new HttpParams();

    samples.forEach((sample) => {
      let draw: string = '';
      let temp: string = '';

      if (sample.temperature) {
        if (typeof sample.temperature === 'string') {
          temp = sample.temperature;
        } else {
          temp = sample.temperature.value;
        }
      }

      if (sample.draw) {
        if (typeof sample.draw === 'string') {
          draw = sample.draw;
        } else {
          draw = sample.draw.value;
        }
      }

      params = params.append('sample', `${sample.id}|${sample.code}|${draw}|${temp}`);
    });

    testCodes.forEach((test) => {
      params = params.append('test', test.contextualizedTestId);
    });

    return this.http
      .get<SampleTestAssociationRes>(this.associationUrl.replace(/[?].*/, ''), {
        headers: headers,
        context: new HttpContext().set(PREVENTS_SAVE_REQUEST, true),
        params,
      })
      .pipe(
        tap((res: SampleTestAssociationRes) => {
          // Reset selections
          this.hasPerformedAssociationsRequest = true;

          const associations = keepExistingAssociations
            ? this.combineExpectedAndAssociationsFromResponse(testCodes, res.associations)
            : res.associations;

          this.reset();

          this.setExpectedSampleTestAssociations(associations);

          this.setSampleTestAssociationErrors(res.errors);
        }),
        map((res) => {
          return res;
        })
      );
  }

  /**
   * TODO Revisit this, and make sure it's being used appropriately. It was removed from use in the getSavableAssociations
   * method because of issues with cancellation logic. The fix was targeted to just that method, as it was a prod support
   * issue, and the other uses of this were not changed during that fix. LG-10593
   * @param orderedTests
   */
  combineTestTreeIntoFlatStructure(orderedTests: Test[]): { [key: string]: AssayWithParent } {
    const orderedTestsFlat: { [key: string]: AssayWithParent } = {};

    function includeAssaysInOrderedTests(tests: Test[], parent?: Test, profile?: Test) {
      tests.forEach((test) => {
        orderedTestsFlat[test.contextualizedTestId] = { assay: test };
        if (parent) {
          orderedTestsFlat[test.contextualizedTestId].panel = parent;
        }
        if (profile) {
          orderedTestsFlat[test.contextualizedTestId].profile = profile;
        }
        if (test.panels) {
          includeAssaysInOrderedTests(test.panels, test, test);
        }
        if (test.assays) {
          includeAssaysInOrderedTests(test.assays, test, profile);
        }
      });
    }

    includeAssaysInOrderedTests(orderedTests);

    return orderedTestsFlat;
  }

  combineExpectedAndAssociationsFromResponse(newTests, responseAssociations) {
    const associations = [];
    let mappedAssociations: { [key: string]: SampleTestAssociation } = {};
    const orderedTests: { [key: string]: AssayWithParent } = this.combineTestTreeIntoFlatStructure(
      this.appStateService.existingAccession.orderedTests
    );
    const newTestsFlat = this.combineTestTreeIntoFlatStructure(newTests);

    this.appStateService.existingAccession.sampleAssociations.forEach((existingAccessionAssociation) => {
      const fullTest = orderedTests[existingAccessionAssociation.contextualizedTestId].assay;
      associations.push(existingAccessionAssociation);
      if (!fullTest) {
        throw new Error('Sample associations are out of sync with ordered tests');
      } else {
        mappedAssociations[fullTest.testId] = existingAccessionAssociation;
      }
    });

    responseAssociations.forEach((responseAssociation) => {
      const fullTest = newTestsFlat[responseAssociation.contextualizedTestId].assay;
      if (mappedAssociations[fullTest.testId]) {
        associations.push({
          contextualizedTestId: fullTest.contextualizedTestId,
          sampleId: mappedAssociations[fullTest.testId].sampleId,
        });
      } else {
        associations.push(responseAssociation);
      }
    });

    Object.values(newTestsFlat).forEach((newTest) => {
      if (
        mappedAssociations[newTest.assay.testId] &&
        !associations.some((association) => association.contextualizedTestId === newTest.assay.contextualizedTestId)
      ) {
        associations.push({
          contextualizedTestId: newTest.assay.contextualizedTestId,
          sampleId: mappedAssociations[newTest.assay.testId].sampleId,
        });
      }
    });

    return associations;
  }

  setExpectedSampleTestAssociations(associations: SampleTestAssociation[]) {
    associations.forEach((item: SampleTestAssociation) => {
      this.expectedSampleTestAssociations.set(item.contextualizedTestId, item.sampleId);
    });
  }

  setSampleTestAssociationErrors(errors: SampleTestAssociationError[]) {
    errors.forEach((item: SampleTestAssociationError) => {
      this.sampleTestAssociationErrors.set(item.contextualizedTestId, item.errorCode);
    });
  }

  // Capture user updates to association data
  updateSampleTestAssociations(formValues: { [key: string]: string }): void {
    // @ts-ignore
    this.updatedSampleTestAssociations = new Map(Object.entries(formValues));
  }

  getSavableAssociations(updatedTests: Test[]) {
    let associations: Map<string, string>;
    if (this.updatedSampleTestAssociations.size > 0) {
      associations = this.updatedSampleTestAssociations;
    } else {
      // No changes made by user
      associations = this.expectedSampleTestAssociations;
    }

    const savable = [];

    // No associations found (e.g. `?` entered as sample)
    if (!associations || associations.size === 0) {
      return savable;
    }

    const allAssays = updatedTests.map((test) => this.getAllAssays(test)).reduce((acc, arr) => acc.concat(arr), []);
    // @ts-ignore
    for (const [assayId, sampleId] of associations) {
      const assay = allAssays.find((assay) => assay.assay.contextualizedTestId == assayId);
      // assay could be undefined if nothing about it was updated
      if (!assay || !this.isCanceled(assay, allAssays)) {
        savable.push({ contextualizedTestId: assayId, sampleId: sampleId });
      }
    }
    return savable;
  }

  private isCanceled(assayWithParent: AssayWithParent, allAssays: AssayWithParent[]): boolean {
    const matchingAssays = allAssays.filter((all) => {
      return all.assay.testId == assayWithParent.assay.testId;
    });
    // an assay is only canceled if every instance of the assay is canceled, in all individual assays, panels, and profiles
    return matchingAssays.every(
      (orderedAssay) =>
        orderedAssay.assay.cancelReasonCode ||
        orderedAssay.panel?.cancelReasonCode ||
        orderedAssay.profile?.cancelReasonCode
    );
  }

  /**
   * Used to get a list of all the assays (including duplicates) to determine what has been canceled or not. We need
   * duplicates, because if any single instance has NOT been canceled, it needs to be sent with the sample association
   * information.
   *
   */
  private getAllAssays(orderedTest: Test, panel?: Test, profile?: Test): AssayWithParent[] {
    function withParent(assay: Test, panel?: Test, profile?: Test) {
      return { assay: assay, panel: panel, profile: profile };
    }

    if (orderedTest.testType == TestType.ASSAY) {
      return [withParent(orderedTest, panel, profile)];
    } else if (orderedTest.testType == TestType.PANEL) {
      return orderedTest.assays.map((assay) => withParent(assay, orderedTest, profile));
    } else {
      let panelAssays: AssayWithParent[] = [];
      let assays: AssayWithParent[] = [];
      if (orderedTest.panels) {
        panelAssays = orderedTest.panels
          ?.map((panel) => this.getAllAssays(panel, null, orderedTest))
          //flatMap would be nice here, but it's only available in es2019+
          .reduce((acc, array) => {
            return acc.concat(array);
          });
      }
      if (orderedTest.assays) {
        assays = orderedTest.assays?.map((a) => withParent(a, null, orderedTest));
      }
      return assays.concat(panelAssays);
    }
  }

  getExpectedAssociations(contextualizedTestId: string): string {
    return this.expectedSampleTestAssociations.get(contextualizedTestId);
  }

  getUpdatedAssociations(contextualizedTestId: string): string {
    return this.updatedSampleTestAssociations.get(contextualizedTestId);
  }

  getCurrentAssociatedSample(contextualizedTestId: string): string {
    // Set form value to stored/current user selection or default to the expected association
    const expectedAssociationValue = this.getExpectedAssociations(contextualizedTestId);
    const updatedAssociationValue = this.getUpdatedAssociations(contextualizedTestId);

    // map values can be null
    if (updatedAssociationValue !== undefined) {
      return updatedAssociationValue;
    }

    if (expectedAssociationValue !== undefined) {
      return expectedAssociationValue;
    }

    return null;
  }

  hasAssociationError(contextualizedTestId: string): boolean {
    return !!this.sampleTestAssociationErrors.get(contextualizedTestId);
  }

  /**
   * We need the blocked test ids here as the client has to know whether a test is blocked to determine if a sample
   * association is actually required, and if it needs to pop the modal before save. Normally the server takes
   * care of this, but the sample association call does not have enough information to correctly identify all the scenarios
   * where it's not required.
   * @param blockedContextualizedTestIds
   */
  isReadyToSave(blockedContextualizedTestIds: readonly string[]): boolean {
    const handledErrors = this.getHandledErrors(blockedContextualizedTestIds);

    const allErrorsResolved =
      !this.sampleTestAssociationErrors || this.sampleTestAssociationErrors.size === handledErrors.length;

    return this.hasFormErrors ? false : allErrorsResolved;
  }

  private getHandledErrors(blockedContextualizedTestIds: readonly string[]) {
    const handledErrors: string[] = [];

    if (!this.sampleTestAssociationErrors || this.sampleTestAssociationErrors.size < 1) {
      return handledErrors;
    }

    // @ts-ignore
    for (const [contextualizedTestId] of this.sampleTestAssociationErrors) {
      const found =
        this.updatedSampleTestAssociations.has(contextualizedTestId) || // we've updated the association
        this.expectedSampleTestAssociations.has(contextualizedTestId) || // the association is from the server
        blockedContextualizedTestIds.indexOf(contextualizedTestId) >= 0; // the test is blocked, and does not require an association
      if (found) {
        handledErrors.push(contextualizedTestId);
      }
    }

    return handledErrors;
  }

  // form values are the FormControls dynamically created via the sample association components
  // initializeSamplesAssociationForm() on ngOnInit from contextualized test id's returned in
  // a sample association response
  setResetState(formValues: any): void {
    this.resetSampleTestAssociations = new Map(Object.entries(formValues));
  }

  reset(): void {
    this.updatedSampleTestAssociations = this.resetSampleTestAssociations;
    this.hasFormErrors = false;
  }

  resetAll(): void {
    this.resetSampleTestAssociations = new Map();
    this.expectedSampleTestAssociations = new Map();
    this.sampleTestAssociationErrors = new Map();
    this.hasPerformedAssociationsRequest = false;
    this.reset();
  }
}

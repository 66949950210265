<div
  id="orderEntryForm"
  class="spot-form"
  autocomplete="off"
  name="orderEntryForm"
  (keydown)="onKeydown($event)"
  [formGroup]="orderEntryForm">
  <fieldset luxLayout="column" [disabled]="isFormDisabled()" id="orderEntryFieldset" class="drag-boundary">
    <div
      luxLayout="row"
      luxLayoutGap="10px"
      luxFlexOffset="35px"
      id="orderEntryFieldsetContent"
      luxLayoutAlign="center start">
      <div
        luxLayout="column"
        luxFlex="15"
        luxLayoutGap="30px"
        luxLayoutAlign="start start"
        class="order-entry-general-information section">
        <!-- COLLECTION DATE -->
        <cl-field-wrapper
          luxFill
          [errors]="orderEntryForm.controls.collectionDate.errors"
          [label]="'LABELS.COLLECTION_DATE' | translate">
          <cl-date-form #collectionDate id="collectionDate" formControlName="collectionDate"></cl-date-form>
        </cl-field-wrapper>
        <!-- /COLLECTION DATE -->

        <!-- ACCESSION NUMBER -->
        <cl-field-wrapper
          luxFill
          id="accessionNumber"
          [required]="true"
          [loading]="!!accessionSub"
          [class.barcode-keep-focus]="shouldKeepFocus('accessionNumber')"
          [label]="'LABELS.ACCESSION_NUMBER' | translate"
          [errors]="orderEntryForm.controls.accessionNumber.errors">
          <input
            #accessionNumber
            clSelectSelf
            (input)="onAccessionNumberInput($event)"
            (keydown.enter)="this.validateAccessionNumber(orderEntryForm.controls.accessionNumber.value)"
            (blur)="
              this.checkValidity(orderEntryForm.controls.accessionNumber);
              this.validateAccessionNumber(orderEntryForm.controls.accessionNumber.value)
            "
            [attr.placeholder]="'PLACEHOLDERS.SEARCH' | translate"
            formControlName="accessionNumber"
            class="spot-form__input"
            spellcheck="false"
            autocomplete="new-item" />
        </cl-field-wrapper>
        <!-- /ACCESSION NUMBER -->

        <!-- BARCODE ID -->
        <div luxLayout="row" luxLayoutGap="10px" luxFlexAlign="start end" luxFill>
          <cl-customer-barcode-field
            #customerBarcodeId
            (beginSearch)="handleCustomerSearch($event)"
            (endSearch)="handleCustomerSearchResponse($event)"
            formControlName="customerBarcodeId"></cl-customer-barcode-field>
        </div>
        <!-- /BARCODE ID -->

        <!-- CUSTOMER CODE -->
        <div luxLayout="row" luxLayoutGap="10px" luxFlexAlign="start end" luxFill>
          <cl-customer-code-field
            #customerCode
            (beginSearch)="handleCustomerSearch($event)"
            (endSearch)="handleCustomerSearchResponse($event)"
            formControlName="customerCode"></cl-customer-code-field>
          <cl-customer-search #customerSearch (orderCustomer)="orderCustomer($event, true)"></cl-customer-search>
        </div>
        <div
          id="selectedCustomer"
          *ngIf="selectedCustomer && !!selectedCustomer.customerCode && lab"
          class="selected-customer spot-typography__text--body"
          [ngClass]="{
            'border-warn': selectedCustomer.petOwnerBillingRequired,
            'inactive-customer': !selectedCustomer.active,
          }"
          luxFill>
          <span id="customerId">{{ selectedCustomer.customerId }}</span>
          <div luxLayout="row" luxLayoutAlign="space-between start">
            <div *ngIf="selectedCustomer.petOwnerBillingRequired" class="customer-code" luxFlex="15%">
              <svg class="spot-icon cl-pob-info-needed" aria-labelledby="title">
                <title>euro</title>
                <use xlink:href="../../assets/icons/spot_icons.svg#euro"></use>
              </svg>
            </div>
            <div luxFlex="85%">
              <div class="customer-name">
                {{ selectedCustomer.name }}
              </div>
              <cl-address-display [address]="selectedCustomer.primaryAddress"></cl-address-display>
              <div class="customer-phone">
                {{ selectedCustomer.phone }}
              </div>
            </div>
          </div>
        </div>
        <!-- /CUSTOMER CODE -->
        <cl-electronic-order-field
          #requisitionId
          formControlName="requisitionInfo"
          [editing]="!!appStateService?.existingAccession"
          [settingUpEorder]="loadingOrderByReqId"
          (beginSearch)="handleReqIdSearch($event)"
          (endSearch)="handleElectronicOrder($event)"
          [searchable]="true"></cl-electronic-order-field>
        <!-- /REQUISITION ID -->
      </div>

      <div luxLayout="column" luxFlex="60" luxLayoutGap="20px">
        <div class="center-column" luxLayout="column" luxLayoutGap="30px">
          <div luxLayout="row" luxLayoutGap="20px">
            <!--VET NAME -->
            <cl-field-wrapper
              luxFlex="33"
              id="vetName"
              [label]="'LABELS.VET_NAME' | translate"
              [errors]="orderEntryForm.controls.vetName.errors">
              <input
                #vetName
                clSelectSelf
                formControlName="vetName"
                class="spot-form__input"
                spellcheck="false"
                autocomplete="new-item" />
            </cl-field-wrapper>
            <!-- /VET NAME-->

            <!--OWNER NAME -->
            <cl-field-wrapper
              luxFlex="33"
              id="ownerName"
              [required]="true"
              [label]="'LABELS.OWNER_NAME' | translate"
              [errors]="orderEntryForm.controls.ownerName.errors">
              <input
                #ownerName
                clSelectSelf
                (blur)="checkValidity(orderEntryForm.controls.ownerName)"
                formControlName="ownerName"
                class="spot-form__input"
                spellcheck="false"
                autocomplete="new-item" />
            </cl-field-wrapper>
            <!-- /OWNER NAME-->

            <!--PET OWNER BILLING-->
            <cl-pet-owner-billing
              luxFlex="33"
              #petOwnerBilling
              formControlName="owner"
              [customer]="selectedCustomer"
              [lab]="lab"
              [label]="'LABELS.PET_OWNER_BILLING' | translate"
              [editing]="!!appStateService?.existingAccession"
              [infoNeeded]="!!(!isEOrder && selectedCustomer && selectedCustomer.petOwnerBillingRequired)"
              [errorValidations]="getErrorValidations('owner')">
            </cl-pet-owner-billing>
            <!--/PET OWNER BILLING-->
          </div>

          <!-- PATIENT ANIMAL TYPE -->
          <div luxLayout="row" luxLayoutGap="20px">
            <div luxFlex="50">
              <cl-animal-type-field
                id="animalType"
                formControlName="animalType"
                [errors]="orderEntryForm.controls.animalType?.errors"
                [placeholder]="'PLACEHOLDERS.SEARCH' | translate"
                (goToNext)="keyboardService.focusNext()"
                (update)="handleAnimalTypeChange($event)"
                [operationalRegionCode]="lab.operationalRegionCode"
                [searchUrl]="animalTypeSearchUrl">
              </cl-animal-type-field>
            </div>
            <cl-field-wrapper
              [hidden]="
                !orderEntryForm.controls.animalType?.value?.supportsCustomType &&
                !orderEntryForm.controls.customAnimalType?.value
              "
              luxFlex="25%"
              id="customAnimalType"
              [label]="'LABELS.PATIENT_ANIMALTYPE_OTHER' | translate"
              [errors]="orderEntryForm.controls.customAnimalType.errors">
              <input
                #customAnimalType
                clSelectSelf
                formControlName="customAnimalType"
                class="spot-form__input"
                [ngClass]="{
                  'inactive-animal-type':
                    orderEntryForm.controls.animalType?.value?.status === AnimalTypeStatus.INACTIVE,
                }"
                spellcheck="false"
                autocomplete="new-item" />
            </cl-field-wrapper>

            <!-- PATIENT NAME -->
            <cl-field-wrapper
              luxFlex="25"
              id="patientName"
              [required]="true"
              [label]="'LABELS.PATIENT_NAME' | translate"
              [errors]="orderEntryForm.controls.patientName.errors">
              <input
                #patientName
                clSelectSelf
                (blur)="checkValidity(orderEntryForm.controls.patientName)"
                formControlName="patientName"
                class="spot-form__input"
                spellcheck="false"
                autocomplete="new-item" />
            </cl-field-wrapper>
            <!-- /PATIENT NAME -->
          </div>
          <!-- /PATIENT ANIMAL TYPE -->

          <div luxLayout="row" luxLayoutGap="20px">
            <!-- PATIENT SEX -->
            <div luxFlex="33">
              <cl-sex-field
                id="patientSex"
                #patientSex
                [data]="availablePatientSexes"
                [focusNextOnWrite]="!loadingOrderByReqId"
                [errors]="orderEntryForm.controls.patientSex?.errors"
                [placeholder]="'PLACEHOLDERS.SEARCH' | translate"
                formControlName="patientSex">
              </cl-sex-field>
            </div>
            <!-- /PATIENT SEX -->

            <!-- PATIENT DOB -->
            <div luxFlex="33">
              <cl-field-wrapper
                luxFill
                [errors]="orderEntryForm.controls.patientAge.errors"
                [label]="'LABELS.PATIENT_AGE' | translate">
                <cl-age-dob id="patientAge" luxFill #patientAge formControlName="patientAge"></cl-age-dob>
              </cl-field-wrapper>
              <!-- /PATIENT AGE DOB -->
            </div>

            <!-- MICROCHIP -->
            <div luxFlex="33">
              <cl-field-wrapper
                luxFill
                id="microchipId"
                [label]="'LABELS.MICROCHIP_ID' | translate"
                [errors]="orderEntryForm.controls.microchipId.errors">
                <input
                  #microchipId
                  clSelectSelf
                  formControlName="microchipId"
                  class="spot-form__input"
                  spellcheck="false"
                  autocomplete="new-item" />
              </cl-field-wrapper>
            </div>
            <!-- /MICROCHIP -->
          </div>
        </div>

        <div luxLayout="row" class="order-entry-samples-test-codes" luxLayoutGap="20px">
          <!-- SAMPLES -->
          <div luxFlex="50" luxLayout="column">
            <cl-samples
              #samples
              luxFlex="50"
              id="samples"
              formControlName="samples"
              (goToNext)="keyboardService.focusNext()"
              [data]="availableSamples"
              [displayAsVerticalList]="true"
              [errors]="orderEntryForm.controls.samples?.errors"
              [isExistingAccession]="isExistingAccession"
              [operationalRegionCode]="lab.operationalRegionCode"
              [placeholder]="'PLACEHOLDERS.SEARCH' | translate"
              [sortByCode]="false"></cl-samples>

            <div class="courier-pickup-container">
              <cl-checkbox
                luxFlex="50px"
                id="courierPickup"
                #courierPickup
                formControlName="courierPickup"
                [text]="'LABELS.COURIER_PICKUP' | translate"></cl-checkbox>
            </div>
          </div>
          <!-- /SAMPLES -->

          <!-- TEST CODES -->
          <cl-test-code-field
            #testCodes
            luxFlex="50"
            id="testCodes"
            formControlName="testCodes"
            [cancelReasons]="appStateService.referenceData.cancelReasons"
            [canceledTests]="appStateService?.existingAccession ? appStateService?.existingAccession.canceledTests : []"
            [animalType]="
              orderEntryForm.controls.animalType?.value !== '?' ? orderEntryForm.controls.animalType?.value : undefined
            "
            [errors]="orderEntryForm.controls.testCodes?.errors"
            [operationalRegionCode]="lab.operationalRegionCode"
            [disableInput]="!!orderEntryForm.get('cancelReasonCode')?.value"
            [placeholder]="'PLACEHOLDERS.SEARCH' | translate"
            (newTests)="handleNewTestAddition($event)"
            (goToNext)="keyboardService.focusNext()"
            (update)="handleTestChange($event)">
            <cl-cancel-reason-selector
              formControlName="cancelReasonCode"
              [cancelReasons]="appStateService.referenceData.cancelReasons"></cl-cancel-reason-selector>
          </cl-test-code-field>
          <!-- /TEST CODES -->
        </div>
      </div>

      <div luxLayout="column" luxLayoutGap="25px" luxFlex="25" class="order-entry-messaging section">
        <!-- DISCOUNT-->
        <cl-discount-field
          id="discount"
          formControlName="discount"
          [errors]="orderEntryForm.controls.discount?.errors"
          (goToNext)="keyboardService.focusNext()"
          [data]="availableDiscounts"
          [placeholder]="'PLACEHOLDERS.SEARCH' | translate"
          #discount>
        </cl-discount-field>
        <!-- /DISCOUNT -->

        <!-- CUSTOMER MESSAGES -->
        <div style="width: 100%">
          <cl-list
            luxFill
            id="customerMessages"
            #customerMessages
            formControlName="customerMessages"
            [allowEdit]="false"
            [allowFreeText]="false"
            [allowMultipleEntry]="true"
            [allowMultipleLines]="true"
            [availableListItems]="availableCustomerMessages"
            [formFieldLabel]="'LABELS.CUSTOMER_MESSAGES'"
            [formFieldName]="'customerMessages'"
            [formFieldRequired]="false"
            [matchAttributes]="['shortCode', 'label']"
            [maxItemsMessage]="'ERRORS_AND_FEEDBACK.MAX_CUSTOMER_MESSAGES'"
            [maxListItems]="maxCustomerMessages"
            [maxTextSize]="1000"
            [placeholder]="'PLACEHOLDERS.SEARCH' | translate"
            [textAttribute]="'text'"
            [processingDelete]="preventSave"
            (asyncDelete)="readyDeclineRecommendation($event)"></cl-list>
        </div>
        <!-- /CUSTOMER MESSAGES -->

        <!-- ESCALATE -->
        <cl-escalate-to-support
          [preventOrderSave]="preventOrderSave()"
          [orderEntryForm]="orderEntryForm"
          [orderResource]="orderResource"
          [selectedCustomer]="selectedCustomer"
          [validationId]="orderValidationService.orderValidationId"></cl-escalate-to-support>
        <!-- /ESCALATE -->
      </div>
    </div>

    <!-- FOOTER -->
    <div id="footer" luxLayout="row" luxLayoutAlign="end center" luxLayoutGap="20px">
      <!-- ORDER SAVE RESPONSE -->
      <div
        id="footer-feedback-content"
        class="spot-typography__text--body"
        luxLayoutAlign="end center"
        luxLayoutGap="8px"
        luxLayout="row">
        <!-- data-accessionLink only here for automated test access -->

        <span *ngIf="testCodes?.loading" id="orderValidationLoadingSpinner">
          <svg class="spot-icon spot-loading-spinner" aria-labelledby="title">
            <title></title>
            <use xlink:href="/assets/icons/spot_icons.svg#spinner"></use>
          </svg>
        </span>

        <span
          *ngIf="orderSaveResponseReceived && orderSaveSuccess && !testCodes?.loading"
          id="orderSaveSuccessMessage"
          [attr.data-accessionLink]="savedAccessionLink">
          <svg aria-labelledby="title">
            <title></title>
            <use [attr.xink:href]="'/assets/icons/spot_icons.svg#checkmark'"></use>
          </svg>
          {{ 'ERRORS_AND_FEEDBACK.SAVE_SUCCESS' | translate }}</span
        >

        <span
          *ngIf="orderSaveResponseReceived && !orderSaveSuccess && !testCodes?.loading"
          data-cy="order-save-error"
          id="orderSaveFailureMessage">
          <svg aria-labelledby="title">
            <title></title>
            <use [attr.xlink:href]="'/assets/icons/spot_icons.svg#alert-notification'"></use>
          </svg>
          {{ 'ERRORS_AND_FEEDBACK.SAVE_ERROR' | translate }}</span
        >

        <span *ngIf="orderValidationService.getShowValidationDisabledMessage()">{{
          'ERRORS_AND_FEEDBACK.VALIDATION_NOT_AVAILABLE' | translate
        }}</span>

        <span
          *ngIf="orderValidationService.blockedTestIds?.length > 0 && !testCodes?.loading"
          luxLayout="row"
          id="orderValidationFeedbackMessage">
          <svg aria-labelledby="title">
            <title></title>
            <use [attr.xlink:href]="'/assets/icons/spot_icons.svg#alert-notification'"></use>
          </svg>
          {{ 'ERRORS_AND_FEEDBACK.RECOMMEND_DNRS' | translate }}
        </span>

        <span
          *ngIf="
            (!orderSaveResponseReceived && orderValidationRequested && !testCodes?.loading) ||
            (samplesAssociationsRequested && !samplesAssociationsReceived)
          "
          id="orderValidationRequestedMessage">
          <svg class="spot-icon spot-loading-spinner" aria-labelledby="title">
            <title></title>
            <use xlink:href="/assets/icons/spot_icons.svg#spinner"></use>
          </svg>
        </span>

        <span *ngIf="samplesAssociationsError" class="samples-association-error" data-cy="samples-associations-error"
          ><svg aria-labelledby="title">
            <title></title>
            <use [attr.xlink:href]="'/assets/icons/spot_icons.svg#alert-notification'"></use></svg
          >{{ 'ERRORS_AND_FEEDBACK.PROBLEM_WITH_SAMPLE_ASSOCIATION_SERVICE' | translate }}</span
        >

        <span *ngIf="displayViewDetails()" id="orderValidationReceivedMessage" luxLayout="row" luxLayoutAlign="8px">
          <span
            *ngIf="
              getOrderValidationItems().length > 0 &&
              orderValidationService.blockedTestIds?.length < 1 &&
              !orderValidationService.getShowValidationDisabledMessage()
            "
            >{{ 'ERRORS_AND_FEEDBACK.VALIDATION_RECEIVED' | translate }}</span
          >

          <cl-order-validation-details
            #orderValidationDetails
            *ngIf="
              getOrderValidationItems().length > 0 &&
              orderValidationRequested === false &&
              orderEntryForm.get('testCodes').value &&
              orderEntryForm.get('testCodes').value.length &&
              orderEntryForm.get('testCodes').value !== orderEntryService.missingInformationGlyph
            "
            [samples]="orderEntryForm.get('samples').value?.samples"
            [orderedItems]="getOrderValidationItems()"></cl-order-validation-details>
        </span>
      </div>
      <!-- /ORDER SAVE RESPONSE -->

      <lux-lab-notes
        #labNotes
        [dragBoundary]="'#orderEntryForm'"
        [getLabNotesLink]="referenceData._links.getLabNotes"
        [addLabNoteLink]="referenceData._links.addLabNote"
        [accessionId]="orderId || appStateService.accessionId"
        [lab]="lab"
        [context]="'ORDER_ENTRY'"
        [labNotesPosition]="{ x: -600, y: -600 }"></lux-lab-notes>

      <!-- FORM BUTTONS -->
      <cl-button
        #saveButton
        id="save-order-button"
        data-dd-action-name="Save button"
        [name]="'save'"
        [disabled]="preventOrderSave()"
        (click)="!preventOrderSave() && checkSampleAssociations() && onSubmit(orderEntryForm)"
        [icon]="'save'"
        [iconPosition]="'left'"
        [type]="orderValidationService.getOrderSaveDisplayType()">
        {{ 'BUTTONS.SAVE' | translate }}
      </cl-button>
      <cl-button
        #resetButton
        id="cancel-order-button"
        data-dd-action-name="Reset button"
        [name]="'reset'"
        [disabled]="loading"
        (click)="!loading && onReset($event)"
        [icon]="'refresh-redo'"
        [iconPosition]="'left'"
        [type]="'secondary'">
        {{ 'BUTTONS.RESET' | translate }}
      </cl-button>
      <!-- /FORM BUTTONS -->
    </div>
    <!-- /FOOTER -->

    <!-- HIDDEN DATA ELEMENTS -->
    <div
      [ngStyle]="appStateService.hasPendingRequests ? { background: 'red' } : { background: 'green' }"
      style="visibility: hidden; padding: 20px; color: white">
      <span data-cy="has-pending-requests">{{ appStateService.hasPendingRequests }}</span>
      <br />
      <span data-cy="order-id">{{ orderId }}</span>
    </div>
    <!-- /HIDDEN DATA ELEMENTS -->
  </fieldset>

  <!-- FORM-LOCK OVERLAY -->
  <!-- Visible when user interaction must be limited/prevented (e.g. resource loading, saving an order) -->
  <div data-cy="field-mask-lock" class="field-mask-lock" *ngIf="displayFormOverlay()">
    <!-- Only display spinner for overlay when we're not using the overlay to lock form focus -->
    <div class="loading-spinner" *ngIf="!shouldKeepFocus()" luxFill luxLayout="row" luxLayoutAlign="center center">
      <svg class="spot-icon spot-loading-spinner" aria-labelledby="title">
        <title>Loading</title>
        <use xlink:href="../../assets/icons/spot_icons.svg#spinner"></use>
      </svg>
    </div>
  </div>
  <!-- /FORM-LOCK OVERLAY -->
</div>
<lux-snackbar
  data-cy="older-then-30-days-snackbar-error"
  #olderThenDaysError
  [showCloseButton]="true"
  (visibilityChange)="handleShowOlderThenDaysErrorVisibilityChange($event)">
  <span data-cy="snackbar-value" class="snackbar-value">
    {{ 'ERRORS_AND_FEEDBACK.ACCESSION_IS_MORE_THAN_30_DAYS_OLD' | translate }}
  </span>
</lux-snackbar>

import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Observable, map, shareReplay } from 'rxjs';
import { PlaceResults, PlaceSuggestion } from '../../models/place-suggestion.model';
import { PlaceDetail } from '../../models/place-detail.model';
import { OrderEntryService } from '../../../order-entry/order-entry.service';
import { AppStateService } from '../../../app-state.service';
import { PREVENTS_SAVE_REQUEST } from '../../../app.component';

interface PlacesCache {
  suggestions: { [key: string]: Observable<PlaceSuggestion[]> };
  details: { [key: string]: Observable<PlaceDetail> };
}

@Injectable()
export class PlacesService {
  private get placesUrl(): string {
    return this.appState.referenceData._links.places.href;
  }
  _cacheSize = 20;
  _cache: PlacesCache = { suggestions: {}, details: {} };

  constructor(
    private appState: AppStateService,
    private http: HttpClient,
    public orderEntryService: OrderEntryService
  ) {}

  getPlaceSuggestions(query: string, customerId: string = '', labId: string): Observable<PlaceSuggestion[]> {
    const cacheKey: string = this.createSuggestionCacheKey(query, customerId, labId);
    const cachedItems = this.getCachedSuggestion(cacheKey);

    if (!cachedItems) {
      const httpOptions = {
        params: this.createSuggestionParams(query, customerId),
        headers: this.appState.addTraceHeader(new HttpHeaders()),
        context: new HttpContext().set(PREVENTS_SAVE_REQUEST, true),
      };

      const cachedPlaceSuggestionCall = this.http.get<PlaceResults>(this.placesUrl, httpOptions).pipe(
        shareReplay(),
        map((response) => {
          return response.results;
        })
      );

      this.cache('suggestions', cacheKey, cachedPlaceSuggestionCall);

      return cachedPlaceSuggestionCall;
    } else {
      return cachedItems;
    }
  }

  getPlaceDetails(placeSuggestion: PlaceSuggestion): Observable<PlaceDetail> {
    const placeDetailUrl: string = placeSuggestion._links.self.href;
    const cachedItem = this.getCachedPlace(placeDetailUrl);

    if (!cachedItem) {
      const cachedPlaceDetailCall = this.http
        .get<PlaceDetail>(placeDetailUrl, {
          headers: this.appState.addTraceHeader(new HttpHeaders()),
          context: new HttpContext().set(PREVENTS_SAVE_REQUEST, true),
        })
        .pipe(
          shareReplay(),
          map((response) => {
            return response;
          })
        );

      this.cache('details', placeDetailUrl, cachedPlaceDetailCall);

      return cachedPlaceDetailCall;
    } else {
      return cachedItem;
    }
  }

  getCachedSuggestion(cacheId: string): Observable<PlaceSuggestion[]> {
    return this._cache.suggestions[cacheId];
  }

  getCachedPlace(placeId: string): Observable<PlaceDetail> {
    return this._cache.details[placeId];
  }

  cache(category: keyof PlacesCache, cacheId, thingToCache: any): void {
    this.cleanCache(category);

    this._cache[category][cacheId] = thingToCache;
  }

  cleanCache(category: keyof PlacesCache): void {
    const keys = Object.keys(this._cache[category]);

    if (keys.length >= this._cacheSize) {
      delete this._cache[category][Object.keys(this._cache[category])[0]];
    }
  }

  uncache(category: keyof PlacesCache, cacheId) {
    delete this._cache[category][cacheId];
  }

  resetCache(): void {
    this._cache.suggestions = {};
    this._cache.details = {};
  }

  createSuggestionCacheKey(query: string, customerId: string, labId: string): string {
    if (customerId) {
      return query + customerId;
    } else {
      return query + labId;
    }
  }

  createSuggestionParams(query: string, customerId: string): any {
    const params: any = {
      searchInput: query,
    };

    if (customerId) {
      params.customerId = customerId;
    }

    return params;
  }
}

import { Injectable } from '@angular/core';
import { Accession } from '../../models/accession.model';
import { UrlUtil } from '../../utils';
import { AppStateService } from '../../../app-state.service';
import { LocaleService } from '@lims-common-ux/lux';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccessionService {
  constructor(
    private appStateService: AppStateService,
    private localeService: LocaleService,
    private http: HttpClient
  ) {}

  loadAccession(accessionId: string): Observable<Accession> {
    const accessionsResourceUrl = UrlUtil.interpolateUrl(this.appStateService.referenceData._links.accession.href, {
      accessionId,
      locale: this.localeService.selectedLocale,
    });

    return this.http.get<Accession>(accessionsResourceUrl, { observe: 'response' }).pipe(
      map((accessionResp) => {
        const accession = accessionResp.body;
        accession.etag = accessionResp.headers.get('etag');
        // Accession number != the id, but it will be more useful than nothing and still identifies an accession well
        // enough. If we ever get the actual ID exposed somehow we could set it, but we don't have it available at the
        // moment
        this.appStateService.configureTraceAndLogging(accessionResp, accession.accessionNumber);
        return accession;
      })
    );
  }
}

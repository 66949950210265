import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { ControlValueAccessor, FormControl, FormGroup } from '@angular/forms';
import { FieldValidators } from '../../shared/components/field/field.validators';
import { Field, FieldLabelDirective } from '../../shared/components/field/field.component';
import { InputComponent } from '../../shared/components/input/input.component';
import { PlacesService } from '../../shared/services/places/places.service';
import { DomUtil } from '../../shared/utils';
import { Customer, PlaceSuggestion, PlaceDetail, Owner } from '../../shared/models';
import { Subscription } from 'rxjs';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { LoggerService, Lab } from '@lims-common-ux/lux';

export class PetOwnerBilling {
  constructor(
    petOwnerBillingOwner?: Owner,
    public street1: string = petOwnerBillingOwner && petOwnerBillingOwner.address?.street1
      ? petOwnerBillingOwner.address.street1
      : null,
    public countryCode: string = petOwnerBillingOwner && petOwnerBillingOwner.address?.countryCode
      ? petOwnerBillingOwner.address.countryCode
      : null,
    public fiscalCode: string = petOwnerBillingOwner && petOwnerBillingOwner.fiscalCode
      ? petOwnerBillingOwner.fiscalCode
      : null,
    public postalCode: string = petOwnerBillingOwner && petOwnerBillingOwner.address?.postalCode
      ? petOwnerBillingOwner.address.postalCode
      : null,
    public city: string = petOwnerBillingOwner && petOwnerBillingOwner.address?.city
      ? petOwnerBillingOwner.address.city
      : null,
    public province: string = petOwnerBillingOwner && petOwnerBillingOwner.address?.province
      ? petOwnerBillingOwner.address.province
      : null,
    public email: string = petOwnerBillingOwner && petOwnerBillingOwner.email ? petOwnerBillingOwner.email : null
  ) {}
}

@Component({
  selector: 'cl-pet-owner-billing',
  templateUrl: './pet-owner-billing.component.html',
  styleUrls: ['./pet-owner-billing.component.scss'],
  providers: Field.Providers(PetOwnerBillingComponent),
})
export class PetOwnerBillingComponent extends Field implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
  @Input() openLabel: string;
  @Input() infoNeeded: boolean = false;
  @Input() lab: Lab;
  @Input() customer: Customer;
  @Input() editing: boolean = false;

  pobRequired: boolean = false; // Modal Icon class

  @ViewChild('fieldWrapper', { static: true }) fieldWrapper;
  @ViewChild('fields', { static: true }) fields;
  @ViewChild('street1', { static: true }) street1: InputComponent;
  @ViewChild('countryCode', { static: true }) countryCode;
  @ViewChild('postalCode', { static: true }) postalCode;
  @ViewChild('city', { static: true }) city;
  @ViewChild('province', { static: true }) province;
  @ViewChild('fiscalCode', { static: true }) fiscalCode;
  @ViewChild('email', { static: true }) email;
  @ViewChild('popover') popover: ElementRef;
  @ViewChild('openButton', { static: true }) openButton: ElementRef;
  @ViewChild('deleteButton') deleteButton: ElementRef;
  @ViewChild('resetButton') resetButton: ButtonComponent;
  @ViewChild('lookup', { static: true }) lookup: InputComponent;
  @ViewChildren('suggestion') suggestions: QueryList<ElementRef>;
  @ContentChild(FieldLabelDirective)
  fieldLabelContent: FieldLabelDirective;

  private _visible: boolean = false;
  _form: FormGroup;
  // Initial form state
  _initial: PetOwnerBilling;
  _hasData: boolean;
  _addressTitle: string;
  _modal_focus_timer: number;
  _focus_timer: number;
  _lookup_timer;
  _addressSuggestions: PlaceSuggestion[] = [];
  _showPopover = false;
  _focusedPlace: PlaceSuggestion;
  _preventShowPopover = false;
  // Form state after save
  _originalData;
  _previewData: { street1; city; province; postalCode; countryCode; fiscalCode; email };
  _data: PetOwnerBilling = null;

  get visible(): boolean {
    return this._visible;
  }

  set data(data) {
    let p,
      found = false;
    this._data = data;
    if (this._data !== null) {
      if (this._onChange) {
        this._onChange(data);
      }
      for (p in data) {
        if (data[p] && data[p].trim && data[p].trim()) {
          this._hasData = true;
          found = true;
        }
      }
    }
    if (found === false) {
      this._hasData = false;
    }
  }

  get data() {
    return this._data;
  }

  placeSuggestionSub: Subscription;
  placeDetailSub: Subscription;
  placeSelectSub: Subscription;

  constructor(
    public element: ElementRef,
    private loggerService: LoggerService,
    public placeService: PlacesService
  ) {
    super(element);
  }

  onKeyDown($event?: KeyboardEvent) {
    if (!$event) {
      return;
    }
    const enteredKey = $event.key.toUpperCase().trim();
    switch (enteredKey) {
      case 'S':
        if ($event.altKey) {
          const errors = this.getErrorState();
          if (Object.keys(errors).length < 1) {
            this.loggerService.logAction('user-action-pob-save-shortcut', {});
            this.save();
          }
        }
        break;
      case 'R':
        if ($event.altKey) {
          this.loggerService.logAction('user-action-pob-reset-focus-shortcut', {});
          this.jumpReset($event);
        }
        break;
      case 'ESCAPE':
        this.loggerService.logAction('user-action-pob-cancel-shortcut', {});
        this.cancel().close();
        break;
      default:
        return;
    }
  }

  ngOnInit() {
    this._form = new FormGroup({
      countryCode: new FormControl('', [
        FieldValidators.maxLength(255),
        FieldValidators.pattern('.*\\S.*', () => ({
          text: 'ERRORS_AND_FEEDBACK.MIN_LENGTH',
          args: { value: 1 },
        })),
      ]),
      fiscalCode: new FormControl('', [
        FieldValidators.maxLength(16),
        FieldValidators.pattern('.*\\S.*', () => ({
          text: 'ERRORS_AND_FEEDBACK.MIN_LENGTH',
          args: { value: 1 },
        })),
      ]),
      email: new FormControl('', [FieldValidators.maxLength(255)]),
      street1: new FormControl('', [
        FieldValidators.maxLength(255),
        FieldValidators.pattern('.*\\S.*', () => ({
          text: 'ERRORS_AND_FEEDBACK.MIN_LENGTH',
          args: { value: 1 },
        })),
      ]),
      postalCode: new FormControl('', [
        FieldValidators.maxLength(255),
        FieldValidators.pattern('.*\\S.*', () => ({
          text: 'ERRORS_AND_FEEDBACK.MIN_LENGTH',
          args: { value: 1 },
        })),
      ]),
      city: new FormControl('', [
        FieldValidators.maxLength(255),
        FieldValidators.pattern('.*\\S.*', () => ({
          text: 'ERRORS_AND_FEEDBACK.MIN_LENGTH',
          args: { value: 1 },
        })),
      ]),
      province: new FormControl('', [
        FieldValidators.maxLength(255),
        FieldValidators.pattern('.*\\S.*', () => ({
          text: 'ERRORS_AND_FEEDBACK.MIN_LENGTH',
          args: { value: 1 },
        })),
      ]),
    });
  }

  ngOnDestroy() {
    if (this._modal_focus_timer) {
      cancelAnimationFrame(this._modal_focus_timer);
      this._modal_focus_timer = 0;
    }
    if (this._focus_timer) {
      cancelAnimationFrame(this._focus_timer);
      this._focus_timer = 0;
    }

    this.unsubscribePlaceSuggestions();
    this.unsubscribePlaceDetailSub();
    this.unsubscribePlaceSelectSub();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.infoNeeded && !changes.infoNeeded.firstChange) {
      this.pobRequired = this.infoNeeded;
    }
  }

  handleLookupFocusin($event) {
    // Related target is null if the user clicks off the lookup, but not in a field. If we don't include this check
    // we get some weird behavior with the popover popping up excessively.
    if (!this._showPopover && $event.relatedTarget) {
      this.lookupAddress($event);
    }
  }

  handleLookupFocusout($event) {
    setTimeout(() => {
      const activeElement = document.activeElement;

      if (!activeElement.classList.contains('suggestion-text')) {
        this.hidePopover($event);
      }
    }, 0);
  }

  addressByIndex(index) {
    return index;
  }

  doLookupAddress(input: string) {
    let customerId: string;

    if (this.customer) {
      customerId = this.customer.customerId;
    }

    this.unsubscribePlaceSuggestions();
    this.showPopover(null);
    this.stopPreview();

    if (!input) {
      this._addressSuggestions = [];
    } else {
      this.placeSuggestionSub = this.placeService.getPlaceSuggestions(input, customerId, this.lab.id).subscribe((r) => {
        if (this._focusedPlace) {
          const index = this._addressSuggestions.indexOf(this._focusedPlace);
          if (index !== -1) {
            const next = r[index] ? index : (r.length = 1);
            this._focusedPlace = r[next];
            this.suggestions.toArray()[next].nativeElement.focus();
            this.previewPlace(this._focusedPlace);
          }
        }

        this._addressSuggestions = r;
        this.placeSuggestionSub = null;
      });
    }
  }

  lookupAddress($event) {
    if ($event && $event.key === 'Enter') {
      $event.preventDefault();
      $event.stopPropagation();
    }
    if (this._lookup_timer) {
      clearTimeout(this._lookup_timer);
    }

    this._lookup_timer = setTimeout(() => {
      this._lookup_timer = 0;
      this.doLookupAddress(this.lookup.value);
    }, 150);
  }

  placeDetailToPOBAddress(placeObj: PlaceDetail) {
    return {
      street1: placeObj.street,
      city: placeObj.city,
      province: placeObj.provinceCode || null,
      postalCode: placeObj.postalCode,
      countryCode: placeObj.countryCode,
      fiscalCode: '',
      email: '',
    };
  }

  previewPlace(place: PlaceSuggestion) {
    this.unsubscribePlaceDetailSub();

    if (!this._showPopover) {
      return;
    }

    this.placeDetailSub = this.placeService.getPlaceDetails(place).subscribe({
      next: (placeObj: PlaceDetail) => {
        const address = this.placeDetailToPOBAddress(placeObj);

        this._form.setValue(address);
        this._previewData = address;
        this.placeDetailSub = null;
      },
      error: (err) => {
        this.placeDetailSub = null;
        this._form.reset();
        this._previewData = null;
      },
    });
  }

  selectPlace(placeSuggestion: PlaceSuggestion) {
    if (!this._showPopover) {
      return;
    }
    this.unsubscribePlaceSelectSub();

    this.placeSelectSub = this.placeService.getPlaceDetails(placeSuggestion).subscribe((placeDetail: PlaceDetail) => {
      this.placeSelectSub = null;
      this._originalData = this.placeDetailToPOBAddress(placeDetail);
      this._form.setValue(this._originalData);
      this._previewData = null;
      this.stopPreview();
      this.lookup.focusInput();
      this.hidePopover(null);
    });
  }

  unsubscribePlaceSuggestions() {
    if (this.placeSuggestionSub) {
      this.placeSuggestionSub.unsubscribe();
      this.placeSuggestionSub = null;
    }
  }

  unsubscribePlaceDetailSub() {
    if (this.placeDetailSub) {
      this.placeDetailSub.unsubscribe();
      this.placeDetailSub = null;
    }
  }

  unsubscribePlaceSelectSub() {
    if (this.placeSelectSub) {
      this.placeSelectSub.unsubscribe();
      this.placeSelectSub = null;
    }
  }

  focusPlace(place: PlaceSuggestion) {
    if (this._focus_timer) {
      cancelAnimationFrame(this._focus_timer);
    }
    this._focusedPlace = place;
    this.previewPlace(place);
  }

  blurPlace() {
    this._focusedPlace = null;
  }

  stopPreview() {
    if (this._focusedPlace) {
      this.previewPlace(this._focusedPlace);
    } else {
      this.unsubscribePlaceDetailSub();
      this._previewData = null;

      // only reset back to original data if we had anything here previously
      const setValue = this._originalData ? this._originalData : this._initial;
      if (setValue) {
        this._form.setValue(setValue);
      }
    }
  }

  showPopover($event) {
    if (this._preventShowPopover) {
      this._preventShowPopover = false;
      return;
    }
    if ($event) {
      $event.preventDefault();
    }
    if (!this._showPopover) {
      this._showPopover = true;
      this._originalData = { ...this._form.value };
    }
  }

  hidePopover($event) {
    if ($event && this._addressSuggestions.length && this._showPopover) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    // If the suggestions popover is not shown, then we don't need to do anything here.
    if (!this._showPopover) {
      return;
    }

    this._focus_timer = requestAnimationFrame(() => {
      this._focus_timer = 0;
      this._showPopover = false;
      this._previewData = this._focusedPlace = null;

      this.stopPreview();

      if (
        document.activeElement === document.body ||
        (!this._showPopover && DomUtil.queryUp(document.activeElement, this.popover?.nativeElement))
      ) {
        this.lookup.focusInput();
        this._showPopover = false;
      }
    });
  }

  prevent($event) {
    $event.preventDefault();
  }

  jumpReset($event) {
    if (!this._visible) {
      return;
    }
    if ($event) {
      $event.preventDefault();
    }
    this.resetButton.focusInput();
  }

  getErrorState() {
    if (!this._form) {
      return false;
    }
    const fields = this._form.controls,
      validations = [],
      result = {};
    let p, validation;
    for (p in fields) {
      if (fields[p] && fields[p].errors) {
        validation = Field.getErrorValidations(fields[p].errors);
        if (validation && validation.length) {
          result[p] = true;
        }
      }
    }
    return result;
  }

  getErrorValidations(name) {
    const field = this._form.get(name);
    return Field.getErrorValidations(field.errors) || [];
  }

  reset() {
    this._form.reset();
    this._originalData = null;
    this._initial = null;
    this.save(null, true);
  }

  clear(): PetOwnerBillingComponent {
    this._form.reset();

    this.lookup.reset();

    this._addressSuggestions = [];

    this._originalData = { ...this._form.value };

    this.lookup.focusInput();

    return this;
  }

  delete(): PetOwnerBillingComponent {
    return this.clear().save();
  }

  open($event?): this {
    if (!this._visible) {
      this._visible = true;
      this._initial = { ...(this.data || (new PetOwnerBilling() as any)) };
    }
    this._form.patchValue(this._initial);
    this.lookup.focusInput();
    this.infoNeeded = false;
    return this;
  }

  save($event?: Event, stopDefaultClose?: boolean): PetOwnerBillingComponent {
    if ($event) {
      this.loggerService.logAction('oelog-pob-form-save', {});
      $event.preventDefault();
    }

    const errors = this.getErrorState();

    if (Object.keys(errors).length < 1) {
      this.data = this._initial = { ...(this._form.value || {}) };
      this._addressTitle = [this.data.postalCode, this.data.city, this.data.province].filter((v) => v).join(' ');
      this._form.patchValue(this.data);

      if (!stopDefaultClose) {
        this.close();
      }
      return this;
    }
  }

  cancel(): PetOwnerBillingComponent {
    if (this.loggerService) {
      this.loggerService.logAction('oelog-pob-form-reset', {});
    }
    this._form.setValue(this._initial || new PetOwnerBilling());
    this.lookup.focusInput();
    return this;
  }

  close(): PetOwnerBillingComponent {
    if (this._visible) {
      this._visible = false;
      this.lookup.reset();
      this._addressSuggestions = [];
      if (this._onTouched) {
        this._onTouched();
      }
    }

    setTimeout(() => {
      this._originalData = null;
      this._initial = null;
      this.focusOpenButton();
    }, 0);

    return this;
  }

  focusOpenButton(): void {
    requestAnimationFrame(() => {
      this.openButton.nativeElement.focus();
    });
  }

  onValueChange(value: PetOwnerBilling) {
    this.data = value;
    if (value === null) {
      this.reset();
      // later we would populate these fields here most likely
    } else {
      let p, control;
      for (p in value) {
        if ((control = this._form.get(p))) {
          control.setValue(value[p]);
        }
      }
    }
  }

  writeValue(value: PetOwnerBilling) {
    this.onValueChange(value);
  }

  /* Modal trigger actions and events */
  onFocus() {
    if (this.infoNeeded && !this.editing) {
      this.pobRequired = true;
      setTimeout(() => {
        this.open();
      });
    }
  }

  focusInput() {
    this.openButton.nativeElement.focus();
  }

  blurInput() {
    this.openButton.nativeElement.blur();
  }
}

import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { ReferenceData, User } from './shared/models';
import { AppService } from './app.service';
import {
  Accession,
  AuditService,
  FlyoutComponent,
  FlyoutNavigationItem,
  HeaderComponent,
  HeaderConfiguration,
  KeyboardService,
  Lab,
  LocaleService,
  ModalContainerService,
  SnackbarComponent,
} from '@lims-common-ux/lux';
import { filter, takeUntil, Subscription, Subject } from 'rxjs';
import { CL_ENV, INDEX_RESOURCE, LABS, CURRENT_USER } from './application-init.service';
import { IndexResource } from './interfaces/indexResource.interface';
import { HelpModalComponent } from './help-modal/help-modal.component';
import { AppStateService } from './app-state.service';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { HttpContextToken } from '@angular/common/http';

export const PREVENTS_SAVE_REQUEST = new HttpContextToken<boolean>(() => false);

@Component({
  selector: 'cl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('header', { static: false })
  header: HeaderComponent;
  labsSub: Subscription;
  existingAccessionSub: Subscription;
  selectedLab: Lab;
  referenceData: ReferenceData;
  selectedLabValue; // cl-select compliant object reflecting current lab selection
  user: User;
  loading: boolean = true;
  accession: Accession;

  // Flyout navigation
  navigationItems: FlyoutNavigationItem[];
  flyoutReady = false; // we need to load data async before we can render the flyout. This will be true once we have that.
  readonly environment: string;
  readonly currentUser: User;
  allFocusableElements;
  headerConfiguration: HeaderConfiguration = {
    accessionLinks: false,
    accessionNumber: false,
    patient: false,
    patientAgeSex: false,
    customerInfo: false,
    dateOriginatingLab: false,
    localeSelector: true,
  };
  loggedIn = false;
  private onDestroy$ = new Subject<void>();

  @ViewChild('flyout') flyout: FlyoutComponent;
  @ViewChild('snackbarError')
  snackbarError!: SnackbarComponent;
  @ViewChild('helpModalContainer', { static: true })
  helpModalContainer: HelpModalComponent;

  @HostListener('mousedown', ['$event'])
  hostMousedown(event) {
    // @ts-ignore
    const activeElement = document.activeElement as ElementRef;
    let target = event.target;

    if (event.target.tagName?.toLowerCase() === 'use' || event.target.tagName?.toLowerCase() === 'svg') {
      target = event.target.closest('button.accession-links-menu-toggle') || target; // class name required to keep change isolated from other icons in order-entry form
    }

    const isFocusable = this.allFocusableElements.filter((item) => item === target).length > 0;

    if (!isFocusable) {
      // Many of our components include UX behaviors triggered on loss of focus. We can not use
      // event.preventDefault. We allow user intent of the click event to bubble, but then force focus back
      // onto the last valid active screen element. This preserves downstream UX effects.
      requestAnimationFrame(() => {
        // @ts-ignore
        activeElement.focus();
      });
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown($evt: KeyboardEvent) {
    // Prevent keyboard interactions while loading
    if (this.loading) {
      this.keyboardService.preventDefaultAndPropagation($evt);
      return false;
    }

    // The flyout in OE is implemented at the app level, while our shortcuts are implemented in
    // the operational region. We have a story/defect to address a possible refactor LG-6885.
    // This block allows for alt + shortcut handling when the flyout has focus to prevent a
    // snackbar error.
    if (this.flyout?.visible && $evt.altKey) {
      this.flyout.closeSidebar();
    }

    if (this.flyout?.visible && $evt.key === 'Escape') {
      this.flyout.closeSidebar();

      requestAnimationFrame(() => {
        this.flyout.focusSidebarToggle();
      });

      return false;
    }

    if (!!this.modalService.openModal) {
      // Modals handle their own key events
      return $evt;
    }

    if (!this.loading) {
      this.keyboardService.handleAppKeydown($evt);
    }
  }

  get missingInformationGlyph(): string {
    return this.appStateService?.referenceData?.mandatoryFieldMissingValue;
  }

  constructor(
    @Inject(INDEX_RESOURCE) private indexResource: IndexResource,
    @Inject(LABS) public labs: Lab[],
    @Inject(CL_ENV) environment: string,
    @Inject(CURRENT_USER) currentUser: User,
    private translate: TranslateService,
    private appService: AppService,
    private appStateService: AppStateService,
    private router: Router,
    private auditService: AuditService,
    private keyboardService: KeyboardService,
    private modalService: ModalContainerService,
    private authService: MsalService,
    private broadcastService: MsalBroadcastService,
    private localeService: LocaleService
  ) {
    this.environment = environment;
    this.currentUser = currentUser;

    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;

    this.broadcastService.inProgress$
      .pipe(
        filter((progress) => {
          return progress === InteractionStatus.None;
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
      });
  }

  ngOnInit() {
    // Show or hide app content and/or loading spinner
    this.appService.showLoadingSpinner.subscribe((value) => {
      this.loading = value;
    });

    this.auditService.auditEventsLink = this.indexResource._links.auditAccessionEvents;

    this.labsSub = this.appService.labSubject.pipe(filter((lab) => !!lab)).subscribe((lab) => {
      this.selectedLab = lab;
      this.selectedLabValue = {
        data: lab.id,
        label: lab.name,
        value: lab.id,
      };
      this.finishLoading();
    });

    this.existingAccessionSub = this.appStateService.existingAccession$.subscribe((value) => {
      this.headerConfiguration = {
        ...this.headerConfiguration,
        dateOriginatingLab: !!value,
        accessionSearch: this.appStateService.referenceData?._links.accessionSearch,
        accessionLinks: !!value?._links.attachments,
        accessionAdvancedSearch: this.appStateService.referenceData?._links.accessionAdvancedSearch,
      };
      if (value?._links.attachments) {
        this.headerConfiguration.attachments = value._links.attachments;
      }
      this.accession = value
        ? ({
            accessionNumber: value.accessionNumber,
            dateCreated: value.dateCreated,
            originatingLabId: value.originatingLabId,
          } as any as Accession)
        : null;
    });
  }

  ngOnDestroy() {
    this.labsSub.unsubscribe();
    this.existingAccessionSub.unsubscribe();
  }

  ngAfterViewChecked() {
    // Allow user to select label text to copy. We use the list of valid, focusable elements
    // from the Keyboard Service. Elements that are focusable by mouse are the same as
    // elements that are focusable by keyboard. We add the `label` element to allow click-and-drag
    // text selection.
    const focusable = this.keyboardService.focussableElements + ', label';
    const allFocusable = document.querySelectorAll(focusable);
    this.allFocusableElements = Array.from(allFocusable);
  }

  updateLab(lab: Lab) {
    this.header.reset();
    this.header.toggleDropdown();
    this.appService.loadingSpinnerVisible = true;
    const url = this.appService.constructLabUrl(lab.id);
    this.router.navigateByUrl(url);
  }

  finishLoading() {
    this.initializeFlyout();
    this.appService.loadingSpinnerVisible = false;
  }

  handleAppRefresh() {
    window.location.reload();
  }

  initializeFlyout(): void {
    const orderEntryFormLinkName: string = this.translate.instant('NAVIGATION.ORDER_ENTRY');
    this.navigationItems = [{ id: 'order-entry', name: orderEntryFormLinkName }];
    setTimeout(() => {
      this.flyoutReady = true;
    });
  }

  helpModalOpen(): void {
    this.helpModalContainer.open();
  }

  handleSnackBarErrorClose() {
    this.appStateService.snackbarErrorClosed();
  }

  triggerNavigation(): void {
    let url = '/order-entry/' + this.selectedLabValue.value;
    if (this.router.routerState.root.snapshot.queryParams['locale']) {
      url += '?locale=' + this.localeService.selectedLocale;
    }

    this.router.navigateByUrl(url).then(() => {
      this.flyout.closeSidebar();
      setTimeout(() => {
        this.flyout.focusSidebarToggle();
      }, 0);
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { DirectivesModule } from '../../shared/directives/directives.module';
import { LuxLayoutModule, ModalContainerModule } from '@lims-common-ux/lux';
import { ComponentsModule } from '../../shared/components/components.module';
import { OrderValidationDetailsComponent } from './order-validation-details.component';
import { OrderValidationService } from './order-validation.service';
import { SampleAssociationModule } from '../../sample-association/sample-association.module';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    DirectivesModule,
    LuxLayoutModule,
    TranslateModule.forChild({}),
    SampleAssociationModule,
    ModalContainerModule,
  ],
  declarations: [OrderValidationDetailsComponent],
  providers: [OrderValidationService, TranslatePipe],
  exports: [OrderValidationDetailsComponent],
})
export class OrderValidationModule {}

import { Component, Input, OnChanges, OnInit } from '@angular/core';
export interface Address {
  street?: string;
  street1?: string;
  street2?: string;
  street3?: string;
  street4?: string;
  city?: string;
  postalCode?: string;
  province?: string;
  provinceCode?: string;
  countryCode?: string;
  fiscalCode?: string;
  email?: string;
}

@Component({
  selector: 'cl-address-display',
  templateUrl: './address-display.component.html',
  styleUrls: ['./address-display.component.scss'],
})
export class AddressDisplayComponent implements OnInit, OnChanges {
  @Input()
  isLineItemDisplay: boolean;

  @Input()
  public address: Address;

  addressFormat: string;

  ngOnInit(): void {
    this.setAddressFormat();
  }

  ngOnChanges(): void {
    this.setAddressFormat();
  }

  setAddressFormat(): void {
    if (this.address) {
      if (this.address.countryCode) {
        this.addressFormat = this.address['countryCode'].trim().toUpperCase();
      } else {
        this.addressFormat = '';
      }
    }
  }
}

import { EventEmitter, Injectable } from '@angular/core';
import { OrderForm } from './shared/models/order-form.model';
import { ReferenceData } from './shared/models/reference-data.model';
import { FeaturesService, LoggerService, PendingRequestService } from '@lims-common-ux/lux';
import { BehaviorSubject, map } from 'rxjs';
import { Accession } from './shared/models/accession.model';
import { Test } from './shared/models/test.model';
import { HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { PREVENTS_SAVE_REQUEST } from './app.component';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  traceId: string;
  private _hasPendingRequests = false;
  private _pendingRequests: HttpRequest<any>[];

  // set during lab resolving, LabResolver, and the AppService.setupLab.
  referenceData: ReferenceData;
  private _initialState: OrderForm = new OrderForm();
  private _orderForm: OrderForm = { ...this._initialState };
  private _orderForm$ = new BehaviorSubject<OrderForm>(null);
  orderForm$ = this._orderForm$.asObservable();

  private _existingAccession: Accession = null;
  private _existingAccession$: BehaviorSubject<Accession> = new BehaviorSubject<Accession>(this._existingAccession);
  public existingAccession$ = this._existingAccession$.asObservable();

  helpModalClosedEvent: EventEmitter<void> = new EventEmitter<void>();
  snackbarErrorClosedEvent: EventEmitter<void> = new EventEmitter<void>();

  accessionId: string;

  constructor(
    private featuresService: FeaturesService,
    private loggerService: LoggerService,
    private pendingRequestService: PendingRequestService
  ) {
    this.pendingRequestService.pendingRequestsList
      .pipe(
        map((pendingRequests) => {
          this._pendingRequests = pendingRequests.filter((req) => req.context.get(PREVENTS_SAVE_REQUEST) === true);
          this._hasPendingRequests = !!this._pendingRequests.length;
          return this.hasPendingRequests;
        })
      )
      .subscribe();
  }

  get pendingRequests(): HttpRequest<any>[] {
    return this._pendingRequests;
  }

  get hasPendingRequests(): boolean {
    return this._hasPendingRequests;
  }

  set orderForm(value) {
    this._orderForm = { ...this._initialState, ...value };
    this._orderForm.patient = { ...this._initialState.patient, ...value.patient };
    this._orderForm$.next(value);
  }

  get orderForm(): OrderForm {
    return this._orderForm;
  }

  set existingAccession(value: Accession) {
    this._existingAccession = value;
    this._existingAccession$.next(this._existingAccession);
  }

  get existingAccession(): Accession {
    return this._existingAccession$.value;
  }

  get orderedTests(): Test[] {
    return this.existingAccession?.orderedTests;
  }

  helpModalClosed() {
    this.helpModalClosedEvent.emit();
  }

  snackbarErrorClosed() {
    this.snackbarErrorClosedEvent.emit();
  }

  resetAccession() {
    this.existingAccession = null;
    this.accessionId = null;
    this._orderForm = new OrderForm();
    this._orderForm$.next(null);
  }

  isActiveFeature(featureName: string): boolean {
    return this.featuresService.hasFeature(featureName);
  }

  /**
   * This should move to be an interceptor.
   * @param httpResp <any> here is valid since we don't care about the contents, we are just looking at the headers
   * @param orderId
   */
  configureTraceAndLogging(httpResp: HttpResponse<any>, orderId: string) {
    // Note: We don't have access to these headers when running locally due to CORS issues.
    this.traceId = httpResp.headers.get('x-cloud-trace-context');
    this.loggerService.traceId = this.traceId;
    this.loggerService.orderId = orderId;
  }

  addTraceHeader(headers: HttpHeaders): HttpHeaders {
    if (this.traceId) {
      return headers.append('x-cloud-trace-context', this.traceId);
    } else {
      return headers;
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { map, of, catchError, debounceTime, BehaviorSubject } from 'rxjs';
import { OrderIdResource } from '../order-entry.service';
import {
  Accession,
  AnimalTypeInputValue,
  ButtonType,
  DescendentQuantity,
  DisplayType,
  ReviewDisplayType,
  Sample,
  Test,
  TestType,
  OVResponse,
} from '../../shared/models';
import { PREVENTS_SAVE_REQUEST } from '../../app.component';
import { AppStateService } from '../../app-state.service';

@Injectable()
export class OrderValidationService {
  orderSaveDisplayType: ButtonType = 'primary';
  showValidationDisabledMessage: boolean = false;
  orderValidationResponse: OVResponse = null;
  blockedTestIds: string[] = [];
  validateManuallyTestIds: string[] = [];
  descendantTestIds: string[] = [];

  private _orderValidationId: BehaviorSubject<string> = new BehaviorSubject(null);

  private manuallyBlockedByUser: BehaviorSubject<Test[]> = new BehaviorSubject([]);
  manuallyBlockedByUser$ = this.manuallyBlockedByUser.asObservable();

  private blockRecommendationDeclinedByUser: string[] = [];

  orderedItems: Test[];

  constructor(
    public http: HttpClient,
    private appStateService: AppStateService
  ) {}

  set orderValidationId(validationId: string) {
    this._orderValidationId.next(validationId);
  }

  get orderValidationId(): string {
    return this._orderValidationId.value;
  }

  get manuallyBlockedContextualizedTestIds(): string[] {
    return this.manuallyBlockedByUser.value.map((test) => test.contextualizedTestId);
  }

  isManuallyBlockedByUser(contextualizedTestId: string): boolean {
    return this.manuallyBlockedContextualizedTestIds.indexOf(contextualizedTestId) > -1;
  }

  addTestBlockedStatusChangedByUser(addTest): void {
    const update = this.manuallyBlockedByUser.value;
    if (update.filter((test) => test.contextualizedTestId === addTest.contextualizedTestId).length < 1) {
      update.push(addTest);
      this.manuallyBlockedByUser.next(update);
    }
  }

  addBlockRecommendationDeclined(contextualizedTestId: string): void {
    if (this.blockRecommendationDeclinedByUser.filter((itemId) => itemId === contextualizedTestId).length < 1) {
      this.blockRecommendationDeclinedByUser.push(contextualizedTestId);
    }
  }

  resetUserChanges(): void {
    this.manuallyBlockedByUser.next([]);
    this.blockRecommendationDeclinedByUser = [];
  }

  validateOrder(
    labId: string,
    resource: OrderIdResource | Accession,
    animalType: AnimalTypeInputValue | string,
    samples: Sample[] | string,
    testIds: Test[] | string,
    missingInformationGlyph: string
  ) {
    const headers: HttpHeaders = this.appStateService.addTraceHeader(new HttpHeaders());
    return this.http
      .put(
        resource._links.validate.href,
        {
          labId: labId,
          testIds:
            !Array.isArray(testIds) || testIds.length === 0
              ? missingInformationGlyph
              : testIds.map((test) => test.testId),
          samples: !Array.isArray(samples)
            ? missingInformationGlyph
            : samples.map((sample) => Sample.createOrderValidationSample(sample)),
          animalTypeCode:
            animalType === missingInformationGlyph
              ? missingInformationGlyph
              : (animalType as AnimalTypeInputValue).animalTypeCode,
        },
        {
          observe: 'response',
          headers: headers,
          context: new HttpContext().set(PREVENTS_SAVE_REQUEST, true),
        }
      )
      .pipe(
        debounceTime(150),
        map((v) => v)
      );
  }

  hasBlockedRecommendation(contextualizedTestId): boolean {
    return (
      this.orderValidationResponse?.actions?.doNotRun?.filter(
        (item) => item.contextualizedTestId === contextualizedTestId
      ).length > 0
    );
  }

  isBlockRecommendationDeclined(contextualizedTestId): boolean {
    return this.blockRecommendationDeclinedByUser.filter((testId) => testId === contextualizedTestId).length > 0;
  }

  declineBlockRecommendation(item: Test) {
    const headers: HttpHeaders = this.appStateService.addTraceHeader(new HttpHeaders());
    let declineUrl = '';
    if (this.orderValidationResponse?.actions && this.orderValidationResponse?.actions.doNotRun) {
      this.orderValidationResponse.actions.doNotRun.forEach((_item, index) => {
        if (item.contextualizedTestId === _item.contextualizedTestId) {
          declineUrl = _item._links.decline.href;
          this.orderValidationResponse.actions.doNotRun.splice(index, 1);
        }
      });
    }

    return this.http
      .put(
        declineUrl,
        {},
        {
          headers: headers,
          context: new HttpContext().set(PREVENTS_SAVE_REQUEST, true),
        }
      )
      .pipe(
        map(() => {
          this.addBlockRecommendationDeclined(item.contextualizedTestId);

          this.parseRecommendations(this.orderedItems, this.orderValidationResponse);
        }),
        catchError(() =>
          of({
            data: null,
            hasServiceError: true,
          })
        )
      );
  }

  refreshPresentation(): void {
    this.parseRecommendations(this.orderedItems, this.orderValidationResponse);
  }

  parseRecommendations(orderedItems?: Test[], orderValidationResponse?: OVResponse, isPartialValidation?: boolean) {
    if (!orderedItems) {
      orderedItems = this.appStateService.existingAccession.orderedTests;
    }

    this.reset();
    this.blockedTestIds = [];
    this.validateManuallyTestIds = [];
    this.descendantTestIds = [];
    this.orderedItems = orderedItems;

    this.orderValidationResponse = orderValidationResponse;

    // This is the happy path: Entering an order, or editing and accession with full (not partial) validation
    if (this.orderValidationResponse?.actions?.doNotRun || this.manuallyBlockedByUser.value.length > 0) {
      // Blocked by order validation
      this.blockedTestIds =
        this.orderValidationResponse?.actions?.doNotRun?.map((item) => {
          if (!this.isBlockRecommendationDeclined(item.contextualizedTestId)) {
            return item.contextualizedTestId;
          }
        }) || [];

      // Blocked by user
      this.manuallyBlockedByUser.value.forEach((item) => {
        if (this.blockedTestIds.indexOf(item.contextualizedTestId) < 0) {
          this.blockedTestIds.push(item.contextualizedTestId);
        }
      });
    }

    // When editing an accession, we need to additionally handle initial load and partial validation updates
    if (
      this.appStateService.existingAccession &&
      (!this.orderValidationResponse || isPartialValidation) &&
      Array.isArray(this.orderedItems)
    ) {
      this.parseExistingAccession();
    }

    this.validateManuallyTestIds =
      (this.orderValidationResponse?.disabledContextualizedTestIds &&
        this.orderValidationResponse?.disabledContextualizedTestIds.map((item) => item)) ||
      [];

    if (Array.isArray(this.orderedItems)) {
      // Set showValidationDisabledMessage
      this.showValidationDisabledMessage =
        this.orderedItems.filter((item) => this.isValidateManually(item.contextualizedTestId)).length > 0;

      this.applyValidationPresentation();

      this.setOrderDisplayType();
    }
  }

  private parseExistingAccession(): void {
    if (!this.appStateService.existingAccession) {
      throw new Error('Existing accession not found');
    }

    // If there is an orderValidationResponse, then this is may or may not be a partial validation scenario.
    // If it's a full validation, this method should not be called
    // If it's a partial validation, we need to update our blockedTestIds with both the this.orderedItems and the existingAccession.orderedTests
    // Alternatively, when there is no orderValidationResponse, then this is the initial load scenario for edit accession and only the  existingAccession.orderedTests is considered
    this.orderedItems.forEach((validatedItem) => {
      if (!this.isBlocked(validatedItem.contextualizedTestId) && validatedItem.doNotRun) {
        this.blockedTestIds.push(validatedItem.contextualizedTestId);
      }

      validatedItem.panels.forEach((panel) => {
        if (!this.isBlocked(validatedItem.contextualizedTestId) && panel.doNotRun) {
          this.blockedTestIds.push(panel.contextualizedTestId);
        }

        panel.assays.forEach((assay) => {
          if (!this.isBlocked(validatedItem.contextualizedTestId) && assay.doNotRun) {
            this.blockedTestIds.push(assay.contextualizedTestId);
          }
        });
      });

      validatedItem.assays.forEach((assay) => {
        if (!this.isBlocked(validatedItem.contextualizedTestId) && assay.doNotRun) {
          this.blockedTestIds.push(assay.contextualizedTestId);
        }
      });
    });
  }

  getShowValidationDisabledMessage() {
    return this.showValidationDisabledMessage;
  }

  getOrderSaveDisplayType() {
    return this.orderSaveDisplayType;
  }

  // Set the color of the order form save button
  setOrderDisplayType() {
    // Default display for all orders including orders with validation disabled items and no alerts, the save button is BLUE
    this.orderSaveDisplayType = 'primary';

    if (this.orderValidationResponse) {
      // Order contains BLOCKED tests, the save button is ORANGE
      if (this.orderValidationResponse.actions) {
        if (
          this.orderValidationResponse.actions.doNotRun?.length > 0 ||
          this.orderValidationResponse.actions.customerMessages?.length > 0
        ) {
          this.orderSaveDisplayType = 'alert';
        }
      }

      // Order is 100% valid, the save button is GREEN
      if (
        (!this.orderValidationResponse.disabledContextualizedTestIds && !this.orderValidationResponse.actions) ||
        ((!this.orderValidationResponse.disabledContextualizedTestIds ||
          this.orderValidationResponse.disabledContextualizedTestIds?.length < 1) &&
          this.orderValidationResponse.actions?.doNotRun?.length < 1 &&
          this.orderValidationResponse.actions?.customerMessages?.length < 1)
      ) {
        this.orderSaveDisplayType = 'positive';
      }
    }
  }

  reset(orderedItems?, missingInformationGlyph?) {
    if (orderedItems && orderedItems.length && orderedItems !== missingInformationGlyph) {
      orderedItems.forEach((item: Test) => {
        item.reviewAlert = false;
        item.reviewPositive = false;
      });
    }
    this.orderSaveDisplayType = 'primary';
    this.showValidationDisabledMessage = false;
    this.orderedItems = null;
    this.orderValidationResponse = null;
    this.blockedTestIds = [];
  }

  getBlockedContextualizedTestIds(): readonly string[] {
    return this.blockedTestIds;
  }

  applyValidationPresentation() {
    this.orderedItems.forEach((item: Test) => {
      item.panels?.forEach((panel: Test) => {
        panel.assays?.forEach((assay: Test) => {
          const panelAssayHasBlockedAncestors = this.hasBlockedAncestors(
            panel.contextualizedTestId,
            item.contextualizedTestId
          );
          const panelAssayHasValidateManuallyAncestors = this.hasValidateManuallyAncestors(
            panel.contextualizedTestId,
            item.contextualizedTestId
          );
          this.setItemDisplayType(assay, panelAssayHasBlockedAncestors, panelAssayHasValidateManuallyAncestors);
        });

        const panelHasBlockedAncestors = this.hasBlockedAncestors(item.contextualizedTestId);
        const panelHasValidateManuallyAncestors = this.hasValidateManuallyAncestors(item.contextualizedTestId);
        this.setItemDisplayType(panel, panelHasBlockedAncestors, panelHasValidateManuallyAncestors);
      });

      item.assays?.forEach((assay) => {
        const assayHasBlockedAncestors = this.hasBlockedAncestors(item.contextualizedTestId);
        const assayHasValidateManuallyAncestors = this.hasValidateManuallyAncestors(item.contextualizedTestId);
        this.setItemDisplayType(assay, assayHasBlockedAncestors, assayHasValidateManuallyAncestors);
      });

      this.setItemDisplayType(item);
    });
  }

  // Apply border color styles to presented tests (BLUE, ORANGE, GREEN border styles)
  setItemDisplayType(item: Test, hasBlockedAncestors?: boolean, hasValidateManuallyAncestors?: boolean) {
    if (this.displayAsValidateManually(item, hasValidateManuallyAncestors)) {
      item.displayType = DisplayType.VALIDATE_MANUALLY;
    }

    if (this.displayAsBlocked(item, hasBlockedAncestors)) {
      item.displayType = DisplayType.BLOCKED;
    }

    if (this.displayAsPositive(item, hasBlockedAncestors, hasValidateManuallyAncestors)) {
      item.displayType = DisplayType.POSITIVE;
    }

    if (item.testType === TestType.PROFILE || item.testType === TestType.PANEL) {
      item.reviewDisplayType = this.setItemReviewDisplayType(item);
    }
  }

  // Apply review indicator dot styles to presented tests (GREEN/ORANGE dots)
  setItemReviewDisplayType(item: Test): ReviewDisplayType {
    let reviewDisplayType = ReviewDisplayType.NONE;

    if (
      item.quantifyDescendents(DisplayType.BLOCKED) === DescendentQuantity.SOME &&
      item.displayType !== DisplayType.BLOCKED
    ) {
      reviewDisplayType = ReviewDisplayType.REVIEW_ALERT;
    }

    if (
      item.quantifyDescendents(DisplayType.POSITIVE) === DescendentQuantity.SOME &&
      item.displayType !== DisplayType.POSITIVE
    ) {
      reviewDisplayType = ReviewDisplayType.REVIEW_POSITIVE;
    }

    if (
      item.quantifyDescendents(DisplayType.BLOCKED) === DescendentQuantity.SOME &&
      item.quantifyDescendents(DisplayType.POSITIVE) === DescendentQuantity.SOME &&
      item.displayType === DisplayType.VALIDATE_MANUALLY
    ) {
      reviewDisplayType = ReviewDisplayType.REVIEW_ALERT_POSITIVE;
    }

    return reviewDisplayType;
  }

  isBlocked(itemId: string): boolean {
    return this.blockedTestIds?.indexOf(itemId) > -1;
  }

  isValidateManually(itemId: string) {
    return this.validateManuallyTestIds?.indexOf(itemId) > -1;
  }

  // Given parent and grandparent ids, determine if either is BLOCKED
  hasBlockedAncestors(parentId: string, grandParentId?: string): boolean {
    return this.isBlocked(parentId) || this.isBlocked(grandParentId);
  }

  // Given parent and grandparent ids, determine if either is VALIDATE_MANUALLY
  hasValidateManuallyAncestors(parentId: string, grandParentId?: string): boolean {
    return this.isValidateManually(parentId) || this.isValidateManually(grandParentId);
  }

  // Present a test with an ORANGE border
  displayAsBlocked(item: Test, hasBlockedAncestors?: boolean) {
    let blocked;
    if (item.testType === TestType.ASSAY) {
      // An assay is blocked if it is individually blocked or if one of its ancestors is explicitly blocked
      blocked =
        (this.isBlocked(item.contextualizedTestId) ||
          (!this.isBlocked(item.contextualizedTestId) && hasBlockedAncestors)) &&
        !this.isBlockRecommendationDeclined(item.contextualizedTestId);
    } else {
      blocked =
        this.isBlocked(item.contextualizedTestId) ||
        (item.quantifyDescendents(DisplayType.BLOCKED) !== DescendentQuantity.NONE &&
          item.quantifyDescendents(DisplayType.VALIDATE_MANUALLY) === DescendentQuantity.NONE);
    }
    return blocked;
  }

  // Present a test with a BLUE border
  displayAsValidateManually(item: Test, hasValidateManuallyAncestors?: boolean): boolean {
    let validateManually;
    if (item.testType === TestType.ASSAY) {
      // An assay is validateManually if it is individually validateManually or if one of its ancestors is explicitly validateManually
      validateManually =
        this.isValidateManually(item.contextualizedTestId) ||
        (!this.isValidateManually(item.contextualizedTestId) && hasValidateManuallyAncestors);
    } else {
      validateManually =
        this.isValidateManually(item.contextualizedTestId) ||
        item.quantifyDescendents(DisplayType.VALIDATE_MANUALLY) !== DescendentQuantity.NONE;
    }

    return validateManually;
  }

  // Present a test with a GREEN border
  displayAsPositive(item: Test, hasBlockedAncestors?: boolean, hasValidateManuallyAncestors?: boolean): boolean {
    return (
      !this.displayAsBlocked(item) &&
      !this.displayAsValidateManually(item) &&
      item.quantifyDescendents(DisplayType.BLOCKED) === DescendentQuantity.NONE &&
      item.quantifyDescendents(DisplayType.VALIDATE_MANUALLY) === DescendentQuantity.NONE &&
      !hasBlockedAncestors &&
      !hasValidateManuallyAncestors &&
      !this.isManuallyBlockedByUser(item.contextualizedTestId)
    );
  }
}

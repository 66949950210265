import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, Observable, of } from 'rxjs';
import { UrlUtil } from '../../shared/utils';
import { OrderEntryService } from '../order-entry.service';
import { ElectronicOrder, ElectronicOrderResponse } from '../../shared/models/electronic-order.model';
import { AppStateService } from '../../app-state.service';
import { LabsService } from '@lims-common-ux/lux';
import { ElectronicOrderAnimalTypeService } from './electronic-order-animal-type.service';
import { AnimalTypeInputValue } from '../../shared/models/animal-type.model';
import { Customer } from '../../shared/models/customer.model';
import { Response } from '../../shared/services/response/response';

@Injectable({
  providedIn: 'root',
})
export class ElectronicOrderService {
  constructor(
    private http: HttpClient,
    private appState: AppStateService,
    private labService: LabsService,
    private electronicOrderAnimalTypeService: ElectronicOrderAnimalTypeService,
    // visible for testing
    public orderEntryService: OrderEntryService
  ) {}

  findByReqId(reqId: string): Observable<ElectronicOrder[]> {
    const headers: HttpHeaders = this.appState.addTraceHeader(new HttpHeaders());
    const url = UrlUtil.interpolateUrl(this.appState.referenceData._links.electronicOrders.href, {
      requisitionId: reqId,
      operationalRegionCode: this.labService.currentLab.operationalRegionCode,
    });

    return this.http.get<ElectronicOrderResponse>(url, { headers }).pipe(
      map((data: ElectronicOrderResponse) => {
        if (!data._embedded?.electronicOrders.length) {
          return [];
        } else {
          return data._embedded.electronicOrders;
        }
      })
    );
  }

  findRequisitionAnimalType(selectedElectronicOrder: ElectronicOrder): Observable<AnimalTypeInputValue> {
    return this.electronicOrderAnimalTypeService.findAnimalTypeByCode(selectedElectronicOrder);
  }

  findElectronicOrderCustomers(
    selectedElectronicOrder: ElectronicOrder,
    existingFormValue: Customer
  ): Observable<Response<Customer[]>> {
    if (!selectedElectronicOrder.customerCode) {
      return of({ data: [] });
    } else if (selectedElectronicOrder.customerCode === existingFormValue.customerCode) {
      return of({ data: [existingFormValue] });
    }

    return this.orderEntryService.getCustomer({ customerCode: selectedElectronicOrder.customerCode });
  }
}

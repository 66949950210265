import { AfterContentInit, AfterViewInit, Component, ElementRef, Inject,ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldValidators } from '../../../shared/components/field/field.validators';
import { OperationalRegionComponent } from '../operational-region.component';
import { OrderEntryService } from '../../order-entry.service';
import { ElectronicOrderService } from '../../electronic-order/electronic-order.service';
import { OrderValidationService } from '../../order-validation/order-validation.service';
import { SamplesAssociationsService } from '../../../sample-association/samples-associations.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {
  GlobalErrorHandlerService,
  KeyboardService,
  LabsService,
  LoggerService,
  LabNotesService,
} from '@lims-common-ux/lux';
import { AppService } from '../../../app.service';
import { AppStateService } from '../../../app-state.service';
import { OrderForm } from '../../../shared/models/order-form.model';
import { ElectronicOrderComponentService } from '../electronic-order-component.service';
import { OrderValidationComponentService } from '../order-validation-component.service';
import { CustomerBarcodeFieldComponent } from '../../customer-barcode-field/customer-barcode-field.component';
import { SampleAssociationComponentService } from '../sample-association-component.service';

@Component({
  selector: 'cl-central-europe-region',
  templateUrl: './central-europe-region.component.html',
  styleUrls: ['../operational-region.component.scss', './central-europe-region.component.scss'],
  providers: [ElectronicOrderComponentService, OrderValidationComponentService],
})
export class CentralEuropeRegionComponent
  extends OperationalRegionComponent
  implements AfterContentInit, AfterViewInit
{
  @ViewChild('customerBarcodeId')
  customerBarcodeId: CustomerBarcodeFieldComponent;

  constructor(
    orderEntryService: OrderEntryService,
    electronicOrderService: ElectronicOrderService,
    orderValidationService: OrderValidationService,
    samplesAssociationsService: SamplesAssociationsService,
    translate: TranslateService,
    element: ElementRef,
    globalErrorHandlerService: GlobalErrorHandlerService,
    loggerService: LoggerService,
    router: Router,
    keyboardService: KeyboardService,
    appService: AppService,
    appStateService: AppStateService,
    labsService: LabsService,
    labNotesService: LabNotesService,
    electronicOrderComponentService: ElectronicOrderComponentService,
    orderValidationComponentService: OrderValidationComponentService,
    sampleAssociationComponentService: SampleAssociationComponentService,
    @Inject('Window') window: Window
  ) {
    super(
      orderEntryService,
      electronicOrderService,
      orderValidationService,
      samplesAssociationsService,
      translate,
      element,
      globalErrorHandlerService,
      loggerService,
      router,
      keyboardService,
      appService,
      appStateService,
      labsService,
      labNotesService,
      electronicOrderComponentService,
      orderValidationComponentService,
      sampleAssociationComponentService,
      window
    );
  }

  ngAfterContentInit() {
    this.addExtraFieldsToForm(this.appStateService.orderForm);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    if (this.customerBarcodeId.value) {
      this.customerBarcodeId.previousSearchValue = this.customerBarcodeId.value;
    }
  }

  addExtraFieldsToForm(initialState: OrderForm) {
    const courierPickupState = initialState.tags.delivery && initialState.tags.delivery === 'courier';
    this.orderEntryForm.addControl('courierPickup', new FormControl(courierPickupState));

    this.orderEntryForm.addControl(
      'customerBarcodeId',
      new FormControl(initialState.customerBarcode, [
        FieldValidators.minLength(1),
        FieldValidators.maxLength(10),
        FieldValidators.pattern('[\\s]*[^\\s]+[\\s]*', () => ({
          text: 'ERRORS_AND_FEEDBACK.NO_SPACES',
        })),
        FieldValidators.pattern('[\\s]*[^\\s]+[\\s]*', () => ({
          text: 'ERRORS_AND_FEEDBACK.NO_SPACES',
        })),
        FieldValidators.pattern('^[\\s]*[0-9]+[\\s]*', () => ({
          text: 'ERRORS_AND_FEEDBACK.MUST_BE_NUM',
        })),
        FieldValidators.pattern('^(?=.*\\S).*$', () => ({
          text: 'ERRORS_AND_FEEDBACK.MIN_LENGTH',
          args: { value: 1 },
        })),
        FieldValidators.serviceLoading(() => this.customerBarcodeId?.searching),
        FieldValidators.noMatches(
          () => this.customerBarcodeId?.hasNoMatches,
          () => 'ERRORS_AND_FEEDBACK.BARCODE_NO_MATCHES'
        ),
        FieldValidators.serviceError(() => this.customerBarcodeId?.hasServiceError),
      ])
    );

    this.orderEntryForm.addControl(
      'owner',
      new FormControl(null, [
        FieldValidators.create(() => {
          const errors = this.petOwnerBilling.getErrorState(),
            ln = Object.keys(errors).length;

          if (ln) {
            return {
              petOwnerBilling: 'ERRORS_AND_FEEDBACK.CONTAINS_ERRORS',
            };
          }

          return null;
        }).message((result) => result && result.petOwnerBilling),
      ])
    );

    // clear the customer if the barcode value changes
    this.orderEntryForm.controls.customerBarcodeId.valueChanges.subscribe((val) => {
      this.selectedCustomer = null;
      this.orderEntryForm.controls.customerCode.reset();
    });
  }

  reset() {
    this.courierPickup?.reset();
    this.orderEntryForm.get('owner')?.reset();
    super.reset();
  }
}

import { Injectable } from '@angular/core';
import { OperationalRegionComponent } from './operational-region.component';
import { SampleTestAssociationRes } from '../../sample-association/sample-test-association';
import { OrderEntryService } from '../order-entry.service';
import { LoggerService } from '@lims-common-ux/lux';
import { SamplesAssociationsService } from '../../sample-association/samples-associations.service';
import { AppStateService } from '../../app-state.service';

@Injectable({
  providedIn: 'root'
})
export class SampleAssociationComponentService {
  #component: (OperationalRegionComponent | null) = null;

  constructor(
    private orderEntryService: OrderEntryService,
    private samplesAssociationsService: SamplesAssociationsService,
    private loggerService: LoggerService,
    private appStateService: AppStateService
  ) { }

  setComponent(component: OperationalRegionComponent) {
    if (this.#component !== null) {
      throw new Error('Operational Region Component already initialized for Order Validation. This service instance should not be reused');
    }

    this.#component = component;
  }

  removeComponent() {
    this.#component = null;
  }

  hasComponent() {
    return this.#component !== null;
  }

  readySamplesAssociations($event) {
    const component = this.#component;
    // Set to a clean state
    this.resetSampleAssociations();

    const runPartially =
      this.appStateService.existingAccession &&
      !component.ovFieldChanges.samplesChanged &&
      !component.ovFieldChanges.animalTypeChanged;

    // Gather prerequisites
    let _samples;
    if (component.orderEntryForm.get('samples').value?.samples.length > 0) {
      _samples = component.orderEntryForm.get('samples').value.samples;
    }

    if (Array.isArray(_samples)) {
      _samples = component.filterRemovedSamples(_samples);
    }

    if (_samples?.length < 1) {
      _samples = null;
    }

    let _tests = null;

    if (Array.isArray(component.orderEntryForm.get('testCodes').value)) {
      _tests = component.orderEntryForm.get('testCodes').value.filter((addedTest) => !addedTest?.cancelReasonCode);
    } else {
      _tests = component.orderEntryForm.get('testCodes').value;
    }
    const tests = runPartially ? component.ovFieldChanges.newTests : _tests;

    // Test prerequisites
    // Roll on if conditions are not met
    if (this.isSamplesEmptyOrMissing(_samples) || this.isTestsEmptyOrMissing(tests)) {
      this.resolveSamplesAssociations();
      if (runPartially) {
        this.updateExistingAccessionSampleAssociations();
      }
    } else {
      // Toggle UX behavior flags
      component.samplesAssociationsRequested = true;
      component.samplesAssociationsReceived = false;
      this.loggerService.logAction('oelog-sample-association-requested', {
        tests,
        samples: _samples,
      });

      // Regardless of service response, or error state, do not lock UX. The User should always be allowed to save.
      component.samplesAssociationsSub = component.samplesAssociationsService
        .associateSamples(_samples, tests, runPartially)
        .subscribe({
          next: (res: SampleTestAssociationRes) => {
            this.resolveSamplesAssociations();
            this.loggerService.logAction('oelog-sample-association-received', {
              ...res,
            });
          },
          error: (err) => {
            // we reset the associations when an error occurs
            // and let the user continue working
            this.resolveSamplesAssociations();
            component.samplesAssociationsError = true;
            this.loggerService.logAction('oelog-sample-association-error', {
              error: err,
            });
          },
        });
    }
  }

  updateExistingAccessionSampleAssociations(): void {
    const sampleAssociations = this.appStateService.existingAccession.sampleAssociations;
    this.samplesAssociationsService.setExpectedSampleTestAssociations(sampleAssociations);
  }

  resolveSamplesAssociations() {
    const component = this.#component;
    component.samplesAssociationsRequested = false;
    component.samplesAssociationsReceived = true;
  }
  
  resetSampleAssociations() {
    const component = this.#component;

    if (this.hasComponent()) {
      if (component?.samplesAssociationsSub) {
        component.samplesAssociationsSub.unsubscribe();
      }

      component.samplesAssociationsRequested = false;
      component.samplesAssociationsReceived = false;
      component.samplesAssociationsError = false;

      component.samplesAssociationsService.resetAll();
    }
  }

  private isSamplesEmptyOrMissing(samples) {
    return !samples || samples.length < 1 || samples === this.orderEntryService.missingInformationGlyph;
  }

  private isTestsEmptyOrMissing(tests) {
    return !tests || tests.length < 1 || tests === this.orderEntryService.missingInformationGlyph;
  }
}

import { PetOwnerBilling } from '../../order-entry/pet-owner-billing/pet-owner-billing.component';
import { StringUtil } from '../utils';
export interface OwnerAddress {
  countryCode: string;
  province: string;
  city: string;
  street1: string;
  postalCode: string;
}

export class OwnerAddress {
  public countryCode: string;
  public province: string;
  public city: string;
  public street1: string;
  public postalCode: string;

  constructor() {}

  static createOwnerAddressFromPOBAddress(petOwnerBillingAddress: PetOwnerBilling): OwnerAddress {
    const ownerAddress: OwnerAddress = new OwnerAddress();

    ownerAddress.countryCode = StringUtil.trim(petOwnerBillingAddress.countryCode);
    ownerAddress.province = StringUtil.trim(petOwnerBillingAddress.province);
    ownerAddress.city = StringUtil.trim(petOwnerBillingAddress.city);
    ownerAddress.street1 = StringUtil.trim(petOwnerBillingAddress.street1);
    ownerAddress.postalCode = StringUtil.trim(petOwnerBillingAddress.postalCode);

    return ownerAddress;
  }

  hasSavableValue(): boolean {
    return !!(this.countryCode || this.province || this.city || this.street1 || this.postalCode);
  }
}

export interface Owner {
  name: string;
  fiscalCode?: string;
  email?: string;
  address?: OwnerAddress;
}

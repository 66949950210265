import { Component, OnInit, Input, AfterViewInit, ElementRef, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { SamplesAssociationsService } from './samples-associations.service';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Test } from '../shared/models/test.model';
import { SampleTestAssociationRow } from './sample-test-association';
import { Sample, SampleTypeStatus } from '../shared/models/sample.model';
import { OrderValidationService } from '../order-entry/order-validation/order-validation.service';
import { LoggerService } from '@lims-common-ux/lux';

@Component({
  selector: 'cl-sample-association',
  templateUrl: './sample-association.component.html',
  styleUrls: ['./sample-association.component.scss'],
})
export class SampleAssociationComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() samples: Sample[];
  @Input() tests: Test[] | string = [];
  @Input() testBlockedStatusChangedByUser: Test[] = [];

  @ViewChildren('needAssociationIcon')
  needAssociationIcons!: QueryList<ElementRef>;

  testRows: SampleTestAssociationRow[] = [];

  samplesAssociationForm: UntypedFormGroup;

  samplesAssociationFormSub = new Subscription();

  constructor(
    private samplesAssociationsService: SamplesAssociationsService,
    private orderValidationService: OrderValidationService,
    private loggerService: LoggerService
  ) {}

  ngOnInit(): void {
    this.samplesAssociationForm = new FormGroup({});

    this.testRows = this.getTestRows();

    this.initializeSamplesAssociationForm();

    this.samplesAssociationFormSub = this.samplesAssociationForm.valueChanges.subscribe((val) => {
      this.samplesAssociationsService.updateSampleTestAssociations(val);
      // Popping this value into the service to share with other areas of the application
      this.samplesAssociationsService.hasFormErrors = this.samplesAssociationForm.invalid;
    });
  }

  ngAfterViewInit(): void {
    this.focusFirstNeedsAttention();
  }

  ngOnDestroy(): void {
    this.samplesAssociationFormSub?.unsubscribe();
  }

  focusFirstNeedsAttention(): void {
    if (this.samplesAssociationForm.invalid) {
      const needsAttention = this.needAssociationIcons && this.needAssociationIcons.first;
      if (needsAttention) {
        setTimeout(() => {
          needsAttention.nativeElement.scrollIntoView({ block: 'center' });
        }, 0);
      }
    }
  }

  removeDuplicateTests(tests: SampleTestAssociationRow[]): SampleTestAssociationRow[] {
    const uniqueMap = {};
    return tests.filter((row) => {
      return !uniqueMap[row.contextualizedTestId] && (uniqueMap[row.contextualizedTestId] = true);
    });
  }

  getTestRows(): SampleTestAssociationRow[] {
    const testRows: SampleTestAssociationRow[] = [];

    if (this.tests && this.tests.length) {
      if (typeof this.tests === 'string') {
        return;
      }

      this.tests.forEach((test: Test) => {
        let fndAssays = false;
        let fndPanels = false;

        // PANEL OR PROFILE WITH ASSAYS: WE DO NOT DISTINGUISH BETWEEN THESE HERE
        if (test.assays && test.assays.length) {
          fndAssays = true;

          const nonAssayRow = this.getNonAssayRow(test);
          testRows.push(nonAssayRow);

          test.assays.forEach((assay: Test) => {
            const assayRow = this.getAssayRow(assay, test.contextualizedTestId);
            testRows.push(assayRow);
          });
        }

        // PROFILE
        if (test.panels && test.panels.length) {
          fndPanels = true;

          test.panels.forEach((panel: Test) => {
            const nonAssayRow = this.getNonAssayRow(panel);
            testRows.push(nonAssayRow);

            panel.assays.forEach((assay: Test) => {
              const assayRow = this.getAssayRow(assay, panel.contextualizedTestId);
              testRows.push(assayRow);
            });
          });
        }

        // STANDALONE ASSAY
        if (!fndAssays && !fndPanels) {
          const assayRow = this.getAssayRow(test);
          testRows.push(assayRow);
        }
      });
    }
    return this.removeDuplicateTests(testRows);
  }

  isManuallyBlocked(contextualizedTestId: string): boolean {
    return this.orderValidationService.isManuallyBlockedByUser(contextualizedTestId);
  }

  displayNeedsAssociationAlert(testRow: SampleTestAssociationRow): boolean {
    return testRow.isAssayRow && this.samplesAssociationForm.controls[testRow?.contextualizedTestId]?.invalid;
  }

  getNonAssayRow(test: Test): SampleTestAssociationRow {
    return {
      isAssayRow: false,
      parentId: null,
      contextualizedTestId: test.contextualizedTestId,
      code: test.testCode,
      name: test.name,
    };
  }

  getAssayRow(test: Test, parentId?: string): SampleTestAssociationRow {
    return {
      isAssayRow: true,
      parentId: parentId || null,
      contextualizedTestId: test.contextualizedTestId,
      code: test.testCode,
      name: test.name,
    };
  }

  initializeSamplesAssociationForm(): void {
    const assayTestRows = this.testRows.filter((item) => item.isAssayRow);

    assayTestRows.forEach((assayTestRow: SampleTestAssociationRow) => {
      const contextualizedTestId = assayTestRow.contextualizedTestId;
      const initialValue = this.samplesAssociationsService.getCurrentAssociatedSample(contextualizedTestId);
      const hasError =
        this.samplesAssociationsService.hasAssociationError(contextualizedTestId) &&
        !this.hasTestBlockedStatusChangedByUser(contextualizedTestId) &&
        !this.isManuallyBlocked(assayTestRow.contextualizedTestId);
      const hasExpected = !!this.samplesAssociationsService.getExpectedAssociations(contextualizedTestId);
      if (hasError || hasExpected) {
        this.samplesAssociationForm.addControl(
          contextualizedTestId,
          new FormControl(initialValue, [Validators.required])
        );
      } else {
        this.samplesAssociationForm.addControl(contextualizedTestId, new FormControl(initialValue));
      }
    });
  }

  hasTestBlockedStatusChangedByUser(contextualizedTestId): boolean {
    return (
      this.testBlockedStatusChangedByUser.filter((test) => test.contextualizedTestId === contextualizedTestId).length >
      0
    );
  }

  catchAndPreventScroll(event, id) {
    event.preventDefault();
    event.stopImmediatePropagation();
    const focusElement = document.getElementById(id);
    if (focusElement) {
      focusElement.focus();
      focusElement.click();
    }
    return false;
  }

  changeAssociation(testCode: string, updateValue: string, event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    const assayRepresentations = this.getAssayRepresentations(testCode);
    this.applyFormSelections(assayRepresentations, updateValue);
    this.loggerService.logAction('oelog-sample-association-change', { testCode, updateValue });
  }

  applyFormSelections(rows: SampleTestAssociationRow[], updateValue: string): void {
    const formPatch = {};
    rows.forEach((item) => {
      formPatch[item.contextualizedTestId] = updateValue;
    });

    setTimeout(() => {
      this.samplesAssociationForm.patchValue(formPatch);
    }, 0);
  }

  getAssayRepresentations(testCode: string): SampleTestAssociationRow[] {
    return this.testRows.filter((item) => item.code === testCode);
  }

  // Panel level operations
  getAssaySampleAssociationsByPanel(panelId): string[] {
    const assaySampleAssociations = [];
    this.testRows.forEach((item) => {
      if (item.parentId && item.parentId === panelId) {
        const controlName = item.contextualizedTestId;
        const currentPanelAssayAssociation = this.samplesAssociationForm.get(controlName).value;
        assaySampleAssociations.push(currentPanelAssayAssociation);
      }
    });
    return assaySampleAssociations;
  }

  isNone(panelId, sampleId: string): boolean {
    const assaySampleAssociations = this.getAssaySampleAssociationsByPanel(panelId);
    return assaySampleAssociations.filter((item) => item !== sampleId).length === assaySampleAssociations.length;
  }

  isAll(panelId, sampleId: string): boolean {
    const assaySampleAssociations = this.getAssaySampleAssociationsByPanel(panelId);
    return (
      assaySampleAssociations.filter((item) => item && item === sampleId).length === assaySampleAssociations.length
    );
  }

  isSome(panelId, sampleId: string): boolean {
    const assaySampleAssociations = this.getAssaySampleAssociationsByPanel(panelId);
    const same = assaySampleAssociations.filter((item) => item === sampleId);
    return same.length < assaySampleAssociations.length && same.length > 0;
  }

  toggleAll(panelId, sampleId: string): void {
    let updateValue;

    // Do not allow toggle to none selected
    if (this.isSome(panelId, sampleId) || this.isNone(panelId, sampleId)) {
      updateValue = sampleId;
    } else {
      return;
    }

    const rows: SampleTestAssociationRow[] = [];

    this.testRows.forEach((item: SampleTestAssociationRow) => {
      if (item.parentId && item.parentId === panelId) {
        const assayRows = this.getAssayRepresentations(item.code);
        assayRows.forEach((assayRow: SampleTestAssociationRow) => {
          rows.push(assayRow);
        });
      }
    });

    this.applyFormSelections(rows, updateValue);
  }
  protected readonly SampleTypeStatus = SampleTypeStatus;
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UrlUtil } from '../../shared/utils';
import { catchError, map, Observable, of, takeLast } from 'rxjs';
import { AppStateService } from '../../app-state.service';
import { LabsService } from '@lims-common-ux/lux';
import { OrderEntryService } from '../order-entry.service';
import { AnimalTypeInputValue } from '../../shared/models/animal-type.model';
import { ElectronicOrder } from '../../shared/models/electronic-order.model';

@Injectable({
  providedIn: 'root',
})
export class ElectronicOrderAnimalTypeService {
  constructor(
    private http: HttpClient,
    private appState: AppStateService,
    private labService: LabsService,
    // visible for testing
    public orderEntryService: OrderEntryService
  ) {}

  findAnimalTypeByCode(electronicOrder: ElectronicOrder): Observable<AnimalTypeInputValue> {
    if (!electronicOrder._links?.animalTypeFind?.href || !electronicOrder.patient?.animalTypeCode) {
      return of(null);
    }

    const headers: HttpHeaders = this.appState.addTraceHeader(new HttpHeaders());

    const url = UrlUtil.interpolateUrl(electronicOrder._links.animalTypeFind.href, {
      animalTypeCode: electronicOrder.patient.animalTypeCode,
      operationalRegionCode: this.labService.currentLab.operationalRegionCode,
    });

    return this.http.get(url, { headers: headers }).pipe(
      takeLast(1),
      map((data: any) => {
        return data;
      }),
      catchError(() =>
        of({
          data: null,
          hasServiceError: true,
        })
      )
    );
  }
}

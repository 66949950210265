import { Directive } from '@angular/core';

@Directive()
export class PreventSaveCondition {
  readonly preventCheck: () => boolean;
  readonly message: string;

  constructor(message: string, condition: () => boolean) {
    this.message = message;
    this.preventCheck = condition;
  }
}

import { PatientAgeDOB } from '../shared/modules/age-dob/age-dob.model';
import { Inject, Injectable, Optional } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, catchError, map, Observable, of, retry, takeLast, tap } from 'rxjs';
import { Response } from '../shared/services/response/response';
import { DateTimeUtil, UrlUtil } from '../shared/utils';
import { Customer } from '../shared/models/customer.model';
import { SampleTestAssociation } from '../sample-association/sample-test-association';
import { LabsService, Link, LoggerService } from '@lims-common-ux/lux';
import { AppService } from '../app.service';
import { AppStateService } from '../app-state.service';
import { IndexResource } from '../interfaces/indexResource.interface';
import { INDEX_RESOURCE } from '../application-init.service';
import { OrderAngularForm, OrderForm } from '../shared/models/order-form.model';
import { Accession } from '../shared/models/accession.model';
import { FieldValidators } from '../shared/components/field/field.validators';
import { OperationalRegionComponent } from './operational-regions/operational-region.component';
import { PREVENTS_SAVE_REQUEST } from '../app.component';
import { Order } from '../shared/models/order.model';
import { TranslateService } from '@ngx-translate/core';
import { OrderValidationService } from './order-validation/order-validation.service';

const ORDER_ID_RETRY_COUNT = 4;

export interface OrderIdResource {
  orderId: string;
  _links: {
    create: Link;
    validate: Link;
    'customer-support-email-content': Link;
  };
}

interface Email {
  address: string;
}

interface CustomerSupportEmail {
  subject: string;
  body: string;
  to?: Email;
}

interface AccessionEditedDiff {
  serviceCloudTxId: string;
  accessionId: string;
  accessionNumber: string;
}

interface AccessionEditedResponse {
  customerSupportPayload?: AccessionEditedDiff;
}

interface AccessionLink {
  _links: { self: Link };
}

function isAccessionLink(object: AccessionLink | AccessionEditedResponse): object is AccessionLink {
  return '_links' in object;
}

@Injectable()
export class OrderEntryService {
  private _electronicOrderVersion: BehaviorSubject<number> = new BehaviorSubject(null);

  // the symbol allowed in 'mandatory fields'
  get missingInformationGlyph(): string {
    return this.appStateService.referenceData.mandatoryFieldMissingValue;
  }

  private get referenceLinks() {
    return this.appStateService.referenceData._links;
  }

  private get indexLinks() {
    return this.indexResource._links;
  }

  set electronicOrderVersion(version: number) {
    this._electronicOrderVersion.next(version);
  }

  get electronicOrderVersion(): number {
    return this._electronicOrderVersion.value;
  }

  constructor(
    @Inject(INDEX_RESOURCE) private indexResource: IndexResource,
    private labService: LabsService,
    private http: HttpClient,
    private loggerService: LoggerService,
    private appStateService: AppStateService,
    private translateService: TranslateService,
    private orderValidationService: OrderValidationService,
    // Optional because it's only used in the LG-6831 which is a spike. Making it non-optional will break a lot of existing
    // tests which we don't really want to update at this point for something that is likely to be ripped out/changed.
    @Optional() private appService: AppService
  ) {}

  getLogPayload(description?: string) {
    return {
      description: description || 'GENERAL',
      orderId: this.loggerService.orderId,
      validationId: this.orderValidationService.orderValidationId,
      pendingRequests: this.appStateService.pendingRequests,
    };
  }

  getOrderId(): Observable<OrderIdResource> {
    this.appStateService.traceId = this.loggerService.traceId = '';

    return this.http
      .post<OrderIdResource>(
        this.indexLinks.orderId.href,
        {
          originatingLabId: this.labService.currentLab.id,
          context: new HttpContext().set(PREVENTS_SAVE_REQUEST, true),
        },
        { observe: 'response' }
      )
      .pipe(
        retry(ORDER_ID_RETRY_COUNT),
        tap((res) => {
          this.appStateService.configureTraceAndLogging(res, res.body.orderId);
        }),
        map((res) => {
          return res.body;
        })
      );
  }

  getCustomer({ customerCode, barcodeId }: { customerCode?; barcodeId? }): Observable<Response<Customer[]>> {
    const headers = this.appStateService.addTraceHeader(new HttpHeaders());
    let params = new HttpParams();

    if (customerCode) {
      params = params.append('exactCustomerCode', customerCode);
    } else if (barcodeId) {
      params = params.append('barcodeId', barcodeId);
    }

    return this.http
      .get(this.indexLinks.customers.href, {
        headers: headers,
        context: new HttpContext().set(PREVENTS_SAVE_REQUEST, true),
        params,
      })
      .pipe(
        takeLast(1),
        map((v) => v['customers'] as Customer[]),
        map((data) => ({
          data,
          hasNoMatches: !data.length,
        })),
        catchError(() =>
          of({
            data: null,
            hasServiceError: true,
          })
        )
      );
  }

  searchCustomers(options: any): Observable<Customer[]> {
    const headers = this.appStateService.addTraceHeader(new HttpHeaders());
    let params = new HttpParams();

    Object.keys(options).forEach((attr) => {
      if (options[attr]) {
        params = params.append(attr, options[attr]);
      }
    });

    return this.http
      .get(this.indexLinks.customers.href, {
        headers: headers,
        context: new HttpContext().set(PREVENTS_SAVE_REQUEST, true),
        params,
      })
      .pipe(map((v) => v['customers'] as Customer[]));
  }

  updateAccession(
    accession: Accession,
    orderForm: FormGroup,
    customer: Customer,
    validationId: string,
    sampleTestAssociations?: SampleTestAssociation[],
    manuallyBlockedContextualizedTestIds?: string[]
  ): Observable<AccessionEditedResponse> {
    const headers = this.appStateService.addTraceHeader(new HttpHeaders()).append('If-Match', accession.etag);
    const serviceCloudTxIds = UrlUtil.getQueryStringParams('serviceCloudTxId');
    return this.putAccession(
      accession._links['edit-accession'],
      headers,
      orderForm,
      customer,
      validationId,
      sampleTestAssociations,
      manuallyBlockedContextualizedTestIds,
      serviceCloudTxIds ? serviceCloudTxIds[0] : null
    );
  }

  saveOrder(
    resource: OrderIdResource,
    orderForm: FormGroup,
    customer: Customer,
    validationId: string,
    sampleTestAssociations?: SampleTestAssociation[],
    manuallyBlockedContextualizedTestIds?: string[]
  ): Observable<AccessionLink> {
    const headers = this.appStateService.addTraceHeader(new HttpHeaders());
    return this.putAccession(
      resource._links.create,
      headers,
      orderForm,
      customer,
      validationId,
      sampleTestAssociations,
      manuallyBlockedContextualizedTestIds
    );
  }

  getCustomerSupportEmail(
    resource: OrderIdResource | Accession,
    orderForm: FormGroup,
    customer: Customer,
    validationId: string,
    sampleTestAssociations?: SampleTestAssociation[]
  ): Observable<CustomerSupportEmail> {
    const headers = this.appStateService.addTraceHeader(new HttpHeaders());
    const order = OrderAngularForm.createOrderFromOrderForm(
      !!this.appStateService.existingAccession,
      orderForm,
      customer,
      this.missingInformationGlyph,
      this.labService.currentLab.id,
      validationId,
      sampleTestAssociations
    );

    return this.http.post<CustomerSupportEmail>(resource._links['customer-support-email-content'].href, order, {
      headers,
    });
  }

  private putAccession(
    link: Link,
    headers: HttpHeaders,
    orderForm: FormGroup,
    customer: Customer,
    validationId: string,
    sampleTestAssociations?: SampleTestAssociation[],
    manuallyBlockedContextualizedTestIds?: string[]
  ): Observable<AccessionLink>;
  private putAccession(
    link: Link,
    headers: HttpHeaders,
    orderForm: FormGroup,
    customer: Customer,
    validationId: string,
    sampleTestAssociations?: SampleTestAssociation[],
    manuallyBlockedContextualizedTestIds?: string[],
    serviceCloudTxId?: string
  ): Observable<AccessionEditedResponse>;
  private putAccession(
    link: Link,
    headers: HttpHeaders,
    orderForm: FormGroup,
    customer: Customer,
    validationId: string,
    sampleTestAssociations?: SampleTestAssociation[],
    manuallyBlockedContextualizedTestIds?: string[],
    serviceCloudTxId?: string
  ): Observable<AccessionLink | AccessionEditedResponse> {
    const order = OrderAngularForm.createOrderFromOrderForm(
      !!this.appStateService.existingAccession,
      orderForm,
      customer,
      this.missingInformationGlyph,
      this.labService.currentLab.id,
      validationId,
      sampleTestAssociations,
      manuallyBlockedContextualizedTestIds,
      this.electronicOrderVersion
    );
    const saveRequest: Order & { serviceCloudTxId?: string } = serviceCloudTxId
      ? { ...order, serviceCloudTxId }
      : order;

    return this.http
      .put<AccessionLink | AccessionEditedResponse>(link.href, saveRequest, {
        headers,
        context: new HttpContext().set(PREVENTS_SAVE_REQUEST, true),
      })
      .pipe(
        tap((resp) => {
          if (!isAccessionLink(resp)) {
            this.postBack(resp.customerSupportPayload);
          }
        })
      );
  }

  /**
   * This will be used in the case an external application (Service Cloud) has opened Order Entry to perform some sort
   * of Customer Support task. Once the save has completed we would want to be able to notify the opening application
   * that we have completed the request so they can proceed with what ever workflows they have on their end
   * @param body
   * @private
   */
  private postBack(body: any) {
    if (!this.appService) {
      return;
    }
    let target: Window = null;
    if (window.opener) {
      target = window.opener;
    } else if (window.parent !== window) {
      // when NOT opened in an iframe the window.parent is just a reference to itself
      target = window.parent;
    }
    if (
      target !== null &&
      this.referenceLinks.serviceCloudOrigin?.href &&
      this.referenceLinks.serviceCloudOrigin?.href !== '*'
    ) {
      // This event content will need to be more defined once we establish what the real caller of this workflow will need.
      // What origin the `postMessage` after save should allow. This needs to be defined to an actual value, and not "*"
      // to make sure we don't send messages somewhere we should not.
      target.postMessage(body, this.referenceLinks.serviceCloudOrigin.href);
    }
  }

  validateAccessionNumber(accessionNumber: string | number): Observable<Response<{ value: string }>> {
    const headers = this.appStateService.addTraceHeader(new HttpHeaders());

    return this.http
      .get(
        UrlUtil.interpolateUrl(this.referenceLinks.barcodeTranslation.href, {
          barcodeValue: accessionNumber,
        }),
        { headers: headers, context: new HttpContext().set(PREVENTS_SAVE_REQUEST, true) }
      )
      .pipe(
        map((data: { value: string }) => ({
          data,
        })),
        catchError((err) =>
          of({
            data: null,
            error: err?.error,
            hasNoMatches: err.status === 400,
            hasServiceError: err.status !== 400,
          })
        )
      );
  }

  createOrderEntryForm(initialState: OrderForm, operationalRegionComponent: OperationalRegionComponent) {
    const { supportsBarcodeTranslation, patientSex, collectionDate, emailFax, discount, customerMessages, samples } =
      operationalRegionComponent;

    let patientAgeDefaultValue: null | PatientAgeDOB = null;

    if (initialState?.patient?.patientAge || initialState?.patient?.dateOfBirth) {
      patientAgeDefaultValue = new PatientAgeDOB(
        initialState?.patient?.patientAge,
        initialState?.patient?.dateOfBirth,
        this.translateService
      );
    }

    return new FormGroup({
      accessionNumber: new FormControl(
        initialState.accessionNumber,
        this.accessionNumberValidation(operationalRegionComponent, supportsBarcodeTranslation)
      ),
      animalType: new FormControl(initialState.patient.animalType),
      cancelReasonCode: new FormControl(initialState.cancelReasonCode),
      collectionDate: new FormControl(initialState.collectionDate, [
        FieldValidators.create((field) => {
          // invalid date
          const errMsg = {
            text: 'ERRORS_AND_FEEDBACK.INVALID_DATE',
          };
          const _date = field.value;
          if (!_date && collectionDate.hasIncompleteDate() && field.dirty) {
            return errMsg;
          }
          return null;
        }).message((errorMsg) => errorMsg),

        FieldValidators.create((field) => {
          // future date
          const errMsg = {
            text: 'ERRORS_AND_FEEDBACK.FUTURE_DATE',
          };
          const _date = field.value;
          if (_date && DateTimeUtil.isFutureDate(_date)) {
            return errMsg;
          }
          return null;
        }).message((errorMsg) => errorMsg),

        FieldValidators.create((field) => {
          // greater than years
          const errMsg = {
            text: 'ERRORS_AND_FEEDBACK.OLDER_THAN_YEARS',
            args: {
              value: 1,
              name: 'Date',
            },
          };
          const _date = field.value;
          if (_date) {
            const _olderThanYears = DateTimeUtil.greaterThanYears(_date, 1);
            if (_olderThanYears) {
              return errMsg;
            }
          }
          return null;
        }).message((errorMsg) => errorMsg),
      ]),
      customAnimalType: new FormControl(initialState.patient.customAnimalType, [FieldValidators.maxLength(50)]),
      customerCode: new FormControl(null, [
        FieldValidators.requiredEvenOnTouch(),
        FieldValidators.minLength(1),
        FieldValidators.maxLength(32),
        FieldValidators.pattern('^(?=.*\\S).*$', () => ({
          text: 'ERRORS_AND_FEEDBACK.MIN_LENGTH',
          args: { value: 1 },
        })),
        FieldValidators.serviceLoading(() => operationalRegionComponent?.customerCode?.searching),
        FieldValidators.noMatches(() => {
          return operationalRegionComponent?.customerCode?.hasNoMatches;
        }),
        FieldValidators.serviceError(() => operationalRegionComponent?.customerCode?.hasServiceError),
        FieldValidators.inactiveCustomerError(() =>
          operationalRegionComponent?.selectedCustomer ? !operationalRegionComponent.selectedCustomer.active : null
        ),
      ]),
      customerMessages: new FormControl(initialState.customerMessages, [
        FieldValidators.noMatches(() => {
          return customerMessages.showNoMatchesFound;
        }),
      ]),
      discount: new FormControl(initialState.discount, [
        FieldValidators.autocompleteNoMatches(discount, this.missingInformationGlyph),
      ]),
      emailFax: new FormControl(null, [
        FieldValidators.emailAndFax(() => {
          // Only UK, Australia, and US regions have email fax fields
          if (emailFax) {
            return {
              value: emailFax.input.nativeElement.value,
              // hide the error on formed values until the user tries to add it
              hideError: emailFax.hideErrors,
            };
          }
        }),
      ]),
      escalateToCustomerSupport: new FormControl(initialState.escalateToCustomerSupport, []),
      microchipId: new FormControl(initialState.patient.microChip, [
        FieldValidators.maxLength(255),
        FieldValidators.pattern('.*\\S.*', () => ({
          text: 'ERRORS_AND_FEEDBACK.MIN_LENGTH',
          args: { value: 1 },
        })),
      ]),
      ownerName: new FormControl(initialState.owner.name, [
        FieldValidators.requiredEvenOnTouch(),
        FieldValidators.minLength(1),
        FieldValidators.maxLength(255),
        FieldValidators.pattern('.*\\S.*', () => ({
          text: 'ERRORS_AND_FEEDBACK.MIN_LENGTH',
          args: { value: 1 },
        })),
      ]),
      patientAge: new FormControl(patientAgeDefaultValue),
      patientName: new FormControl(initialState.patient.name, [
        FieldValidators.requiredEvenOnTouch(),
        FieldValidators.minLength(1),
        FieldValidators.maxLength(255),
        FieldValidators.pattern('.*\\S.*', () => ({
          text: 'ERRORS_AND_FEEDBACK.MIN_LENGTH',
          args: { value: 1 },
        })),
      ]),
      patientSex: new FormControl(initialState.patient.sex, [
        FieldValidators.autocompleteNoMatches(patientSex, this.missingInformationGlyph),
      ]),
      pimsOrderId: new FormControl(initialState.pimsOrderId, [FieldValidators.maxLength(255)]),
      requisitionInfo: new FormControl(initialState.requisitionInfo, [this.requisitionInfoValidator()]),
      samples: new FormControl(initialState.samples, [
        FieldValidators.requiresValidSampleValue(),
        FieldValidators.autocompleteNoMatches(samples, this.missingInformationGlyph, false),
      ]),
      testCodes: new FormControl(initialState.orderedTests),
      vetName: new FormControl(initialState.veterinarian.name, [FieldValidators.maxLength(255)]),
    });
  }

  private accessionNumberValidation(
    operationalRegionComponent: OperationalRegionComponent,
    supportsBarcodeTranslation: boolean
  ) {
    let validators = [
      FieldValidators.create((field) => {
        // This error can present as result of user input -> service call (validateAccessionNumberResponse),
        // or as a result of a failed order save (duplicateAccessionErrorMessageOnSave).
        let errMsg;

        if (!operationalRegionComponent.duplicateAccessionErrorMessageOnSave) {
          errMsg = operationalRegionComponent.validateAccessionNumberResponse?.error?.displayErrorMessage;
        } else if (operationalRegionComponent.duplicateAccessionErrorMessageOnSave) {
          errMsg = operationalRegionComponent.duplicateAccessionErrorMessageOnSave;
        }

        if (errMsg) {
          const err = {
            text: errMsg,
          };

          return err;
        }
        return null;
      }).message((errorMsg) => errorMsg),
      FieldValidators.requiredEvenOnTouch(),
      FieldValidators.pattern('^[A-Za-z0-9]+', () => ({
        text: 'ERRORS_AND_FEEDBACK.MUST_BE_ALPHA',
      })),
    ];

    // This is only applicable to the Central Europe region
    if (supportsBarcodeTranslation) {
      validators.push(
        // weird pattern here due to global OR central europe region formats being accepted. If we ever get
        // to only supporting the single, global, barcode format this can be cleaned up and use the exactLength
        // validator again
        FieldValidators.pattern('^.{8}$|^.{10}$', () => ({
          text: 'ERRORS_AND_FEEDBACK.INVALID_ACCESSION_NUMBER',
        })),
        FieldValidators.serviceError(() => operationalRegionComponent.validateAccessionNumberResponse.hasServiceError),
        FieldValidators.serviceLoading(() => operationalRegionComponent.accessionSub),
        FieldValidators.noMatches(
          () => operationalRegionComponent.validateAccessionNumberResponse.hasNoMatches,
          () => 'ERRORS_AND_FEEDBACK.INVALID_ACCESSION_NUMBER'
        )
      );
    } else {
      validators.push(FieldValidators.minLength(3), FieldValidators.maxLength(10));
    }
    return validators;
  }

  private requisitionInfoValidator(): ValidatorFn {
    return (control): ValidationErrors | null => {
      if (control.value?.requisitionId.length > 32) {
        return {
          _maxLength: {
            message: { text: 'ERRORS_AND_FEEDBACK.MAX_LENGTH', args: { value: 32 } },
            value: { actualLength: control.value.requisitionId.length, requiredLength: 32 },
          },
        };
      } else if (!/^(.*\S.{0,1000}){0,1000}$/.exec(control.value?.requisitionId)) {
        return { _pattern: { message: { text: 'ERRORS_AND_FEEDBACK.MIN_LENGTH', args: { value: 1 } } } };
      } else {
        return null;
      }
    };
  }
}
